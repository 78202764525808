import React from 'react'
import {Switch} from 'react-router-dom'
import ProtectedRoute from 'src/app/routers/ProtectedRoute'
import DashboardScreens from './Screens'
import DashboardPage from './page/DashboardPage'

const DashboardRoutes: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={DashboardScreens.Dashboard.PATH}
        screen={DashboardScreens.Dashboard}
        component={DashboardPage}
      />
    </Switch>
  )
}

export default DashboardRoutes

export const DashboardBreadcrumb: any[] = [
  {
    isActive: false,
    path: DashboardScreens.Dashboard.PATH,
    title: DashboardScreens.Dashboard.TITLE_ALT,
  },
]

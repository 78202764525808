import { Bounce, toast } from "react-toastify"
import GIcon from "../Libs/Icon/GIcon"

export const showToast = (text: string) => {
    toast.success(text, {
      position: 'top-center',
      className: 'bg-success-50 border border-success-500 text-neutral-700 font-normal text-[16px] w-[600px]',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
      icon: <GIcon icon='IconCheckCircleOutline' className='text-success-500'/>,
      closeButton: false,
      transition: Bounce,
    })
  }
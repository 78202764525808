/* eslint-disable import/no-anonymous-default-export */
export default {
  RESPONSE_CODE: {
    SUCCESS: 'MANDIRI-LEADS-0000',
    INVALID_USER_PASSWORD: 'MANDIRI-LEADS-0001',
    INVALID_TOKEN: 'MANDIRI-LEADS-0002',
    EXPIRED_TOKEN: 'MANDIRI-LEADS-0003',
    INVALID_CAPTCHA: 'MANDIRI-LEADS-0004',
    INVALID_REFRESH_TOKEN: 'MANDIRI-LEADS-0005',
    USER_BLOCKED: 'MANDIRI-LEADS-0006',
    USER_NOT_FOUND: 'MANDIRI-LEADS-0007',
    BAD_REQUEST: 'MANDIRI-LEADS-0017',
    PASSWORD_EXCEED_LIMIT: 'MANDIRI-LEADS-0018',
    NIP_NOT_FOUND: 'MANDIRI-LEADS-0025',
    FILE_TYPE_MISMATCH: 'MANDIRI-LEADS-0037',

    DOUBLE_ACCESS: 'MANDIRI-LEADS-9993',
    FORBIDDEN: 'MANDIRI-LEADS-9996',
    ACCESS_DENIED: 'MANDIRI-LEADS-9997',
    INVALID_PARAM: 'MANDIRI-LEADS-9998',
    INTERNAL_SERVER_ERROR: 'MANDIRI-LEADS-9999',

    NETWORK_ERROR: 'ERR_NETWORK',
  },
}

import GIcon from '../Libs/Icon/GIcon'
import {GModal} from '../Libs'

interface LoadingScreenProps {
  isloading: boolean
  text?: any
}

const LoadingScreen = ({isloading, text}: LoadingScreenProps) => {
  return (
    <GModal
      show={isloading}
      onHide={() => {}}
      className='bg-transparent flex flex-1 justify-center flex-row w-screen '
    >
      <div className='top-0 h-screen w-full flex justify-center'>
        <div className='flex flex-col justify-center'>

        <GIcon icon='IconLoadingSignature' className='animate-spin self-center'  />
        <span className='text-white text-lg font-semibold mt-4 text-center'>{text ?? 'Loading...'}</span>
        </div>
      </div>
    </GModal>
  )
}

export default LoadingScreen

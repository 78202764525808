import React, {useEffect, useMemo} from 'react'
import {Route, RouteProps, useParams} from 'react-router-dom'
import {PageTitle} from '../components/Layout/header/HeaderTitle'
import {Card} from '../components/Libs'
import {ScreenProp} from '../models/screen-prop'
import {getTitle} from '../utils/title-utils'
import {usePermissions} from '../hooks/permissions-hook'
import GIcon from '../components/Libs/Icon/GIcon'

interface Props extends RouteProps {
  screen: ScreenProp
  children?: React.ReactNode
}

const defaultScreen = {
  PATH: '',
  TITLE: '',
  breadcrumbs: () => [],
}

const ProtectedRoute: React.FC<Props> = ({
  component: Component,
  children,
  path,
  screen,
  ...rest
}) => {
  const {hasAccess} = usePermissions()
  return (
    <Route
      {...rest}
      render={(props) => {
        if (hasAccess(screen.PERMISSION)) {
          return Component ? <Component {...props} /> : children
        }
        return <ProtectedPage {...screen} />
      }}
    />
  )
}

//eslint-disable-next-line
const ProtectedPage: React.FC<ScreenProp> = ({
  TITLE,
  TITLE_ALT,
  HEADER_NAME,
  breadcrumbs = defaultScreen.breadcrumbs,
}) => {
  const params = useParams()
  //eslint-disable-next-line
  useMemo(() => breadcrumbs(params), [params])

  useEffect(() => {
    document.title = getTitle(TITLE_ALT)
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <PageTitle description={TITLE}>{HEADER_NAME ?? TITLE_ALT}</PageTitle>
      <Card.Root className='rounded-lg' style={{minHeight: 'calc(100% - 30px)'}}>
        <Card.Body className='flex flex-col items-center justify-center'>
          <GIcon icon={'IconWarningCircle'} className='w-20 h-20 mb-4 text-warning'></GIcon>
          <h1 className='text-xl text-neutral-800'>Anda tidak mempunyai akses ke halaman ini</h1>
        </Card.Body>
      </Card.Root>
    </>
  )
}

export default ProtectedRoute

import {Order} from 'src/app/models/api.types'

export type CrudPermission = 'create' | 'read' | 'update' | 'delete' | 'approve'

export const ModuleMenu = [
  {
    menu_code: 'menu_pipeline_distribution',
    combinations: ['DISTRIBUSI_NEW_LEADS', 'DISTRIBUSI_EXISTING_LEADS'],
  },
  {menu_code: 'menu_sales_fronting', combinations: ['SALES_FRONTING']},
  {
    menu_code: 'menu_approval',
    combinations: [
      'APPROVAL_PROSPECTING',
      'APPROVAL_IDEB_SLIK',
      'APPROVAL_PINDAH_KELOLA',
      'APPROVAL_CANVASSING',
    ],
  },
  {
    menu_code: 'menu_monitoring',
    combinations: [
      'MONITOR_GEOTAGGING',
      'MONITOR_HEAD_OFFICE',
      'MONITOR_DISTRIBUTION_HEAD',
      'MONITOR_KANTOR_CABANG',
      'MONITOR_UNIT',
      'MONITOR_ROP',
      'MONITOR_AOP',
      'DASHBOARD_AUDIT_TRAIL',
    ],
  },
  {
    menu_code: 'menu_parameter_master_data',
    combinations: ['UPLOAD_DATA_LEADS', 'PARAMETER_CONFIGURATION'],
  },
]

export type ModulePermissionQuery =
  | 'menu_home.create'
  | 'menu_home.read'
  | 'menu_home.update'
  | 'menu_home.delete'
  | 'menu_pipeline_distribution.create'
  | 'menu_pipeline_distribution.read'
  | 'menu_pipeline_distribution.update'
  | 'menu_pipeline_distribution.delete'
  | 'menu_sales_fronting.create'
  | 'menu_sales_fronting.read'
  | 'menu_sales_fronting.update'
  | 'menu_sales_fronting.delete'
  | 'menu_approval.create'
  | 'menu_approval.read'
  | 'menu_approval.update'
  | 'menu_approval.delete'
  | 'menu_monitoring.create'
  | 'menu_monitoring.read'
  | 'menu_monitoring.update'
  | 'menu_monitoring.delete'
  | 'menu_parameter_master_data.create'
  | 'menu_parameter_master_data.read'
  | 'menu_parameter_master_data.update'
  | 'menu_parameter_master_data.delete'
  | 'menu_role_management.create'
  | 'menu_role_management.read'
  | 'menu_role_management.update'
  | 'menu_role_management.delete'
  | any
export interface Role {
  id?: string
  name?: string
  is_active?: boolean
  is_all_access?: boolean
  permissions?: Permissions
  created_at?: string
  updated_at?: string
  deleted_at?: string
}

export interface RoleMenu {
  menu_code?: string
  menu_name?: string
  can_read?: boolean
  can_insert_update?: boolean
  can_delete?: boolean
  can_approve?: boolean
}

export interface RoleQuery {
  page?: number
  size?: number
  is_active?: boolean
  search?: string
  sort?: string
  order?: Order
}

export interface Permissions {
  role_id?: string
  permissions?: ModulePermissionQuery[]
  created_at?: string
  updated_at?: string
  deleted_at?: string
}

export interface RoleDetail extends Role {
  roles_module_permissions?: {
    id?: string
    role_id?: string
    module_id?: string
    active_permissions?: CrudPermission[]
    created_at?: string
    updated_at?: string
    deleted_at?: string
    module_permission?: RoleAccessTemplate
  }[]
}

export interface RoleAccessTemplate {
  id?: string
  code?: string
  name?: string
  group_code?: string
  permissions?: CrudPermission[]
  active_permissions?: CrudPermission[]
  sequence?: number
  is_active?: boolean
  created_at?: string
  updated_at?: string
  deleted_at?: string
  group?: {
    code?: string
    name?: string
    sequence?: number
    is_active?: boolean
    created_at?: string
    updated_at?: string
    deleted_at?: string
  }
}

export interface ISaveRole {
  name?: string
  is_active?: boolean
  roles_module_permissions?: {
    module_id?: string
    active_permissions?: CrudPermission[]
    sequence?: number
    is_active?: boolean
  }[]
}

export type EditRoleParams = {
  roleCode: string
}

export type RoleDetailParams = {
  roleId: string
}

import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const CaseManagementScreens = {
  CASE_MANAGEMENT: {
    PATH: '/case-management',
    TITLE: 'Case Management',
    TITLE_ALT: 'Case Management',
    breadcrumbs: defaultBreadcrumbs,
  },
  // REINSTATEMENT
  LIST_REINSTATEMENT: {
    PATH: '/case-management/reinstatement/list',
    HEADER_NAME: 'Reinstatement',
    TITLE: 'Reinstatement',
    TITLE_ALT: 'Reinstatement',
    PERMISSION: 'reinstatement.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_REINSTATEMENT: {
    PATH: '/case-management/reinstatement/list/edit/:id',
    TITLE: 'Edit Reinstatement',
    TITLE_ALT: 'Edit Reinstatement',
    PERMISSION: 'reinstatement.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_REINSTATEMENT: {
    PATH: '/case-management/reinstatement/list/detail/:id',
    TITLE: 'Reinstatement Details',
    TITLE_ALT: 'Reinstatement Details',
    PERMISSION: 'reinstatement.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default CaseManagementScreens

import axios from 'axios'
import {BaseResponse, Response} from 'src/app/models/api.types'
import {LoginByEmailModel} from '../models/Auth.model'

//new
export const LOGIN_BY_EMAIL = `/api/web/auth/login`
export const GENERATE_CAPTCHA = `/api/web/auth/captcha`
export const LOGOUT = `/api/web/auth/logout`

//old
export const CHANGE_PASSWORD = `/api/v1/auth/change-password`
export const RESET_PASSWORD = `/api/v1/auth/forgot-password`
export const REQUEST_RESET_PASSWORD = `/api/v1/auth/forgot-password-confirmation`
export const VERIFY_RESET_PASSWORD = `/api/v1/auth/forgot-password-detail`

export const loginByEmail = (data?: any, cancelToken?: any) => {
  return axios.post<BaseResponse<Response<LoginByEmailModel>>>(LOGIN_BY_EMAIL, data, {cancelToken})
}

export const changePassword = (data?: any, config?: any) => {
  return axios.post(CHANGE_PASSWORD, data, config)
}

export const requestResetPassword = (data?: any, cancelToken?: any) => {
  return axios.post(REQUEST_RESET_PASSWORD, data, {
    cancelToken,
  })
}

export const verifyResetPassword = (id: string) => {
  return axios.get(`${VERIFY_RESET_PASSWORD}/${id}`)
}

export const saveResetPassword = (id?: string, data?: any) => {
  return axios.put(`${RESET_PASSWORD}/${id}/new-password`, data)
}

export const generateCaptcha = (uid: string) => {
  return axios.post(`${GENERATE_CAPTCHA}`, {captcha_uid: uid}, {responseType: 'blob'})
}

export const logout = (headers?: any) => {
  return axios.post(`${LOGOUT}`, '', {headers})
}

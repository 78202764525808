import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../components/Layout/FallbackView'
import AuditTrailScreens from '../modules/audit-trail/Screens'
import CaseManagementScreens from '../modules/case-management/Screens'
import FaqScreens from '../modules/faq/Screens'
import UserRoleScreens from '../modules/permissions/Screens'
import ProfileScreens from '../modules/profile/Screens'
import {ModulesComponentScreens} from '../modules_component/Screens'
import HomeScreens from '../modules/home/Screens'
import PipelineDistributionScreens from '../modules/pipeline-distribution/Screens'
import SalesFrontingScreens from '../modules/sales-fronting/Screens'
import ApprovalScreens from '../modules/approval/Screens'
import MonitoringScreens from '../modules/monitoring/Screens'
import ParameterMasterDataScreens from '../modules/parameter-master-data/Screens'
import UserRoleManagementScreens from '../modules/user-role-management/Screens'
import DashboardScreens from '../modules/dashboard/Screens'
import DashboardPage from '../modules/dashboard/page/DashboardPage'
import BlackBoxScreens from '../modules/blackbox/Screens'
import MailboxScreens from '../modules/mailbox/Screens'
import ReportScreens from '../modules/report/Screens'

export function PrivateRoutes() {
  const HomePage = lazy(() => import('../modules/home/HomeRoutes'))
  const PipelineDistributionPage = lazy(
    () => import('../modules/pipeline-distribution/PipelineDistributionRoutes')
  )
  const SalesFrontingPage = lazy(() => import('../modules/sales-fronting/SalesFrontingRoutes'))
  const ApprovalPage = lazy(() => import('../modules/approval/ApprovalRoutes'))
  const MonitoringPage = lazy(() => import('../modules/monitoring/MonitoringRoutes'))
  const ParameterMasterDataPage = lazy(
    () => import('../modules/parameter-master-data/ParameterMasterDataRoutes')
  )
  const UserManagementPage = lazy(
    () => import('../modules/user-role-management/UserRoleManagementRoutes')
  )

  const UserRolePage = lazy(() => import('../modules/permissions/UserRoleRoutes'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfileRoutes'))
  const CaseManagementPage = lazy(() => import('../modules/case-management/CaseManagementRoutes'))
  const AuditTrailPage = lazy(() => import('../modules/audit-trail/AuditTrailRoutes'))
  const FaqPage = lazy(() => import('../modules/faq/FaqRoutes'))
  const BlackBoxPage = lazy(() => import('../modules/blackbox/BlackBoxRoutes'))
  const MailboxPage = lazy(() => import('../modules/mailbox/MailboxRoutes'))
  const ReportPage = lazy(() => import('../modules/report/ReportRoutes'))

  //modules_components
  const ModulesComponentsPage = lazy(() => import('../modules_component/ModulesComponentRoutes'))

  const defaultRoute = HomeScreens.Home.PATH
  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path={HomeScreens.Home.PATH} component={HomePage} />
          <Route path={HomeScreens.AkunSaya.PATH} component={HomePage} />

          <Route path={DashboardScreens.Dashboard.PATH} component={DashboardPage} />
          <Route
            path={PipelineDistributionScreens.PipelineDistribution.PATH}
            component={PipelineDistributionPage}
          />
          <Route path={SalesFrontingScreens.SalesFronting.PATH} component={SalesFrontingPage} />
          <Route path={SalesFrontingScreens.ViewSalesFronting.PATH} component={SalesFrontingPage} />
          <Route path={ApprovalScreens.Approval.PATH} component={ApprovalPage} />
          <Route path={MonitoringScreens.Monitoring.PATH} component={MonitoringPage} />
          <Route
            path={ParameterMasterDataScreens.ParameterMasterData.PATH}
            component={ParameterMasterDataPage}
          />
          <Route
            path={UserRoleManagementScreens.UserManagement.PATH}
            component={UserManagementPage}
          />
          <Route
            path={UserRoleManagementScreens.RoleManagement.PATH}
            component={UserManagementPage}
          />
          <Route path={UserRoleManagementScreens.AddRole.PATH} component={UserManagementPage} />
          <Route
            path={UserRoleManagementScreens.ViewApprovedRole.PATH}
            component={UserManagementPage}
          />
          <Route
            path={UserRoleManagementScreens.ViewUnapprovedRole.PATH}
            component={UserManagementPage}
          />
          <Route path={UserRoleScreens.USER_ROLE.PATH} component={UserRolePage} />
          <Route path={ProfileScreens.PROFILE.PATH} component={ProfilePage} />
          <Route path={CaseManagementScreens.CASE_MANAGEMENT.PATH} component={CaseManagementPage} />
          <Route path={AuditTrailScreens.AUDIT_TRAIL.PATH} component={AuditTrailPage} />
          <Route path={BlackBoxScreens.BlackBox.PATH} component={BlackBoxPage} />
          <Route path={FaqScreens.FAQ.PATH} component={FaqPage} />
          <Route
            path={ModulesComponentScreens.MODULES_COMPONENT.PATH}
            component={ModulesComponentsPage}
          />
          <Route
            path={ApprovalScreens.ViewApprovalProspecting.PATH}
            component={ApprovalPage}
          ></Route>
          <Route path={ApprovalScreens.ApprovalClosing.PATH} component={ApprovalPage}></Route>
          <Route path={ApprovalScreens.KSMCalculator.PATH} component={ApprovalPage}></Route>
          <Route path={ApprovalScreens.PINPENCalculator.PATH} component={ApprovalPage}></Route>
          <Route path={ApprovalScreens.EMANCalculator.PATH} component={ApprovalPage}></Route>
          <Route path={ApprovalScreens.ApprovalClosingDetail.PATH} component={ApprovalPage}></Route>
          <Route path={MailboxScreens.Mailbox.PATH} component={MailboxPage}></Route>
          <Route path={MailboxScreens.MailboxParameter.PATH} component={MailboxPage}></Route>
          <Route path={ReportScreens.REPORT.PATH} component={ReportPage}></Route>
          <Redirect from='/auth' to={defaultRoute} />
          <Redirect exact from='/' to={defaultRoute} />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    </>
  )
}

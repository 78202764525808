import {FC} from 'react'

const DisabledMenu: FC = () => {
  return (
    <div
      data-testid={'disabled-menu'}
      className='absolute top-0 bottom-0 left-0 right-0 z-10 p-3 mx-3 rounded-lg bg-danger/20'
    >
      <div className='flex items-center justify-center w-full font-semibold -skew-y-6 select-none text-fs-9 text-white/70'>
        On Development
      </div>
    </div>
  )
}

export default DisabledMenu

import React from 'react'
import {Card} from 'src/app/components/Libs'
import imageUtils from 'src/app/utils/image-utils';

const GuestWrapper: React.FC<{withTitle?: boolean; children?: React.ReactNode}> = ({
  withTitle = true,
  children,
}) => {
  return (
    <div
      data-testid='content-wrapper-component'
      className='flex items-center justify-center w-screen h-screen py-18 bg-neutral-10'
    >
      <div className='h-max-[688px] w-[540px]'>
        <Card.Root className='flex items-center justify-between h-full py-8 rounded-lg'>
          <div className='flex justify-center w-[356px] mb-2'>
            <img src={imageUtils.LogoNavigatorBlue} alt='Navigator' className='h-30' id='app-logo' />
          </div>

          <div className='w-[356px]'>{children}</div>
        </Card.Root>
      </div>
    </div>
  )
}

export default GuestWrapper

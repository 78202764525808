import {cva} from 'class-variance-authority'

export const alertTheme = cva(['flex', 'items-center', 'rounded', 'py-2', 'px-3'], {
  variants: {
    colors: {
      primary: ['text-primary', 'bg-primary-light-2'],
      success: ['text-success', 'bg-success-light-2'],
      danger: ['text-danger', 'bg-danger-light-2'],
      warning: ['text-warning', 'bg-warning-light-2'],
      info: ['text-info', 'bg-info-light-2'],
    },
  },
  compoundVariants: [
    {
      colors: 'danger',
    },
  ],
  defaultVariants: {
    colors: 'danger',
  },
})

import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const ParameterMasterDataScreens = {
  ParameterMasterData: {
    PATH: '/parameter-master-data',
    PERMISSION: '',
    TITLE: 'Parameter & Master Data'
  },
  MasterDataInsurance: {
    PATH: '/parameter-master-data/parameter/insurance',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Insurance',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  AddMasterDataInsurance: {
    PATH: '/parameter-master-data/parameter/insurance/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Insurance',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  DetailMasterDataInsurance: {
    PATH: '/parameter-master-data/parameter/insurance/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Insurance',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  UpdateMasterDataInsurance: {
    PATH: '/parameter-master-data/parameter/insurance/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Insurance',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  DetailMasterDataInsuranceHistory: {
    PATH: '/parameter-master-data/parameter/insurance-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Insurance',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  UpdateMasterDataInsuranceHistory: {
    PATH: '/parameter-master-data/parameter/insurance-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Insurance',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  MasterDataCredit: {
    PATH: '/parameter-master-data/parameter/credit',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Credit',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  AddMasterDataCredit: {
    PATH: '/parameter-master-data/parameter/credit/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Credit',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  DetailMasterDataCredit: {
    PATH: '/parameter-master-data/parameter/credit/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Credit',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  UpdateMasterDataCredit: {
    PATH: '/parameter-master-data/parameter/credit/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Credit',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  DetailMasterDataCreditHistory: {
    PATH: '/parameter-master-data/parameter/credit-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Credit',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  UpdateMasterDataCreditHistory: {
    PATH: '/parameter-master-data/parameter/credit-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Credit',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  Parameter: {
    PATH: '/parameter-master-data/parameter',
    TITLE: 'Parameter',
    TITLE_ALT: 'Parameter',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  MasterData: {
    PATH: '/parameter-master-data/master-data',
    TITLE: 'Master Data',
    TITLE_ALT: 'Master Data',
    PERMISSION: 'UPLOAD_DATA_LEADS.read',
  },
  DetailMasterData: {
    PATH: '/parameter-master-data/master-data/detail/:id',
    TITLE: 'Detail Data Leads',
    TITLE_ALT: 'Detail Data Leads',
    PERMISSION: 'UPLOAD_DATA_LEADS.read',
  },
  UploadMasterData: {
    PATH: '/parameter-master-data/master-data/upload',
    TITLE: 'Upload Data Leads',
    TITLE_ALT: 'Upload Data Leads',
    PERMISSION: 'UPLOAD_DATA_LEADS.insert_update',
  },
  MasterDataDivision: {
    PATH: '/parameter-master-data/parameter/division',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Division',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  AddMasterDataDivision: {
    PATH: '/parameter-master-data/parameter/division/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Division',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  DetailMasterDataDivision: {
    PATH: '/parameter-master-data/parameter/division/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Division',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  UpdateMasterDataDivision: {
    PATH: '/parameter-master-data/parameter/division/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Division',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  DetailMasterDataDivisionHistory: {
    PATH: '/parameter-master-data/parameter/division-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Division',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  UpdateMasterDataDivisionHistory: {
    PATH: '/parameter-master-data/parameter/divison-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Division',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  MasterDataDistribution: {
    PATH: '/parameter-master-data/parameter/dist',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Distribution',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  AddMasterDataDistribution: {
    PATH: '/parameter-master-data/parameter/dist/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Distribution',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  DetailMasterDataDistribution: {
    PATH: '/parameter-master-data/parameter/dist/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Distribution',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  UpdateMasterDataDistribution: {
    PATH: '/parameter-master-data/parameter/dist/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Distribution',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  DetailMasterDataDistributionHistory: {
    PATH: '/parameter-master-data/parameter/dist-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Distribution',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  UpdateMasterDataDistributionHistory: {
    PATH: '/parameter-master-data/parameter/dist-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Distribution',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  Config: {
    PATH: '/parameter-master-data/parameter/',
    TITLE: 'Configuration',
    TITLE_ALT: 'Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  BankConfig: {
    PATH: '/parameter-master-data/parameter/bank-config',
    TITLE: 'Bank Configuration',
    TITLE_ALT: 'Bank Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  BankConfigApprove: {
    PATH: '/parameter-master-data/parameter/bank-config-approve',
    TITLE: 'Bank Configuration',
    TITLE_ALT: 'Bank Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  BankAddConfig: {
    PATH: '/parameter-master-data/parameter/bank-config/add',
    TITLE: 'Bank Configuration',
    TITLE_ALT: 'Bank Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  BankDetailConfig: {
    PATH: '/parameter-master-data/parameter/bank-config/detail/:id',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Bank Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  BankDetailBreadcrumbs: [
    {
      isActive: false,
      path: '/parameter-master-data/parameter/bank-config',
      title: 'Parameter',
    },
    {isActive: false, path: '', title: '', isSeparator: true},
  ],
  BankEditConfig: {
    PATH: '/parameter-master-data/parameter/bank-config/edit/:id',
    TITLE: 'Bank Configuration',
    TITLE_ALT: 'Bank Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  BranchConfig: {
    PATH: '/parameter-master-data/parameter/branch-config',
    TITLE: 'Branch Configuration',
    TITLE_ALT: 'Branch Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  BranchAddConfig: {
    PATH: '/parameter-master-data/parameter/branch-config/add',
    TITLE: 'Branch Configuration',
    TITLE_ALT: 'Branch Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  BranchDetailConfig: {
    PATH: '/parameter-master-data/parameter/branch-config/detail/:id',
    TITLE: 'Branch Configuration',
    TITLE_ALT: 'Branch Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  BranchEditConfig: {
    PATH: '/parameter-master-data/parameter/branch-config/edit/:id',
    TITLE: 'Branch Configuration',
    TITLE_ALT: 'Branch Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  MenuConfig: {
    PATH: '/parameter-master-data/parameter/menu-config',
    TITLE: 'Menu Configuration',
    TITLE_ALT: 'Menu Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  ProductConfig: {
    PATH: '/parameter-master-data/parameter/product-config',
    TITLE: 'Product Configuration',
    TITLE_ALT: 'Product Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  ProductDetailConfig: {
    PATH: '/parameter-master-data/parameter/product-config/detail/:id',
    TITLE: 'Product Configuration',
    TITLE_ALT: 'Product Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  ProductAddConfig: {
    PATH: '/parameter-master-data/parameter/product-config/add',
    TITLE: 'Product Configuration',
    TITLE_ALT: 'Product Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  ProductEditConfig: {
    PATH: '/parameter-master-data/parameter/product-config/edit/:id',
    TITLE: 'Product Configuration',
    TITLE_ALT: 'Product Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  MenuEditConfig: {
    PATH: '/parameter-master-data/parameter/menu-config/edit/:id',
    TITLE: 'Menu Configuration',
    TITLE_ALT: 'Menu Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  MenuDetailConfig: {
    PATH: '/parameter-master-data/parameter/menu-config/detail/:id',
    TITLE: 'Menu Configuration',
    TITLE_ALT: 'Menu Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.read',
  },
  MenuAddConfig: {
    PATH: '/parameter-master-data/parameter/menu-config/add',
    TITLE: 'Menu Configuration',
    TITLE_ALT: 'Menu Configuration',
    PERMISSION: 'PARAMETER_CONFIGURATION.insert_update',
  },
  MasterDataLeadsType: {
    PATH: '/parameter-master-data/parameter/leads-type',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Leads Type',
  },
  DetailMasterDataLeadsType: {
    PATH: '/parameter-master-data/parameter/leads-type/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Data Leads Type',
    breadcrumbs: defaultBreadcrumbs,
  },
  UpdateMasterDataLeadsType: {
    PATH: '/parameter-master-data/parameter/leads-type/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Data Leads Type',
    breadcrumbs: defaultBreadcrumbs,
  },
  DetailMasterDataLeadsTypeHistory: {
    PATH: '/parameter-master-data/parameter/leads-type-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Data Leads Type',
    breadcrumbs: defaultBreadcrumbs,
  },
  UpdateMasterDataLeadsTypeHistory: {
    PATH: '/parameter-master-data/parameter/leads-type-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Data Leads Type',
    breadcrumbs: defaultBreadcrumbs,
  },
  MasterDataMitraBankBayar: {
    PATH: '/parameter-master-data/parameter/mitra-bank-bayar',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Mitra Kantor Bayar',
  },
  AddMasterDataMitraBankBayar: {
    PATH: '/parameter-master-data/parameter/mitra-bank-bayar/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Mitra Kantor Pembayaran',
    breadcrumbs: defaultBreadcrumbs,
  },
  DetailMasterDataMitraBankBayar: {
    PATH: '/parameter-master-data/parameter/mitra-bank-bayar/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Mitra Kantor Pembayaran',
    breadcrumbs: defaultBreadcrumbs,
  },
  UpdateMasterDataMitraBankBayar: {
    PATH: '/parameter-master-data/parameter/mitra-bank-bayar/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Mitra Kantor Pembayaran',
    breadcrumbs: defaultBreadcrumbs,
  },
  DetailMasterDataMitraBankBayarHistory: {
    PATH: '/parameter-master-data/parameter/mitra-bank-bayar-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Mitra Kantor Pembayaran',
    breadcrumbs: defaultBreadcrumbs,
  },
  UpdateMasterDataMitraBankBayarHistory: {
    PATH: '/parameter-master-data/parameter/mitra-bank-bayar-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Mitra Kantor Pembayaran',
    breadcrumbs: defaultBreadcrumbs,
  },
  MasterDataReason: {
    PATH: '/parameter-master-data/parameter/reason',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Reason',
  },
  AddMasterDataReason: {
    PATH: '/parameter-master-data/parameter/reason/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Reason',
    breadcrumbs: defaultBreadcrumbs,
  },
  DetailMasterDataReason: {
    PATH: '/parameter-master-data/parameter/reason/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Reason',
    breadcrumbs: defaultBreadcrumbs,
  },
  UpdateMasterDataReason: {
    PATH: '/parameter-master-data/parameter/reason/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Reason',
    breadcrumbs: defaultBreadcrumbs,
  },
  DetailMasterDataReasonHistory: {
    PATH: '/parameter-master-data/parameter/reason-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Reason',
    breadcrumbs: defaultBreadcrumbs,
  },
  UpdateMasterDataReasonHistory: {
    PATH: '/parameter-master-data/parameter/reason-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Reason',
    breadcrumbs: defaultBreadcrumbs,
  },
  MasterCalculator: {
    PATH: '/parameter-master-data/parameter/calculator',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Parameter Kalkulator',
  },
  AddMasterCalculatorGajiPokok : {
    PATH: '/parameter-master-data/parameter/calculator/gapok/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Parameter Kalkulator Gaji Pokok',
  },
  DetailMasterCalculatorGajiPokok : {
    PATH: '/parameter-master-data/parameter/calculator/gapok/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Parameter Kalkulator Gaji Pokok',
  },
  UpdateMasterCalculatorGajiPokok : {
    PATH: '/parameter-master-data/parameter/calculator/gapok/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Parameter Kalkulator Gaji Pokok',
  },
  ImportMasterCalculatorGajiPokok : {
    PATH: '/parameter-master-data/parameter/calculator/gapok/import',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Import Parameter Kalkulator Gaji Pokok',
  },
  AddMasterCalculatorPensiunanPokok : {
    PATH: '/parameter-master-data/parameter/calculator/penpok/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Parameter Kalkulator Pensiunan Pokok',
  },
  DetailMasterCalculatorPensiunanPokok : {
    PATH: '/parameter-master-data/parameter/calculator/penpok/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Parameter Kalkulator Pensiunan Pokok',
  },
  UpdateMasterCalculatorPensiunanPokok : {
    PATH: '/parameter-master-data/parameter/calculator/penpok/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Parameter Kalkulator Pensiunan Pokok',
  },
  ImportMasterCalculatorPensiunanPokok : {
    PATH: '/parameter-master-data/parameter/calculator/penpok/import',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Import Parameter Kalkulator Pensiunan Pokok',
  },
  AddMasterCalculatorPangkatGolongan : {
    PATH: '/parameter-master-data/parameter/calculator/pangkat-golongan/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Parameter Kalkulator Pangkat Golongan',
  },
  DetailMasterCalculatorPangkatGolongan : {
    PATH: '/parameter-master-data/parameter/calculator/pangkat-golongan/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Parameter Kalkulator Pangkat Golongan',
  },
  UpdateMasterCalculatorPangkatGolongan : {
    PATH: '/parameter-master-data/parameter/calculator/pangkat-golongan/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Parameter Kalkulator Pangkat Golongan',
  },
  MasterProductType: {
    PATH: '/parameter-master-data/parameter/product-type',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Product Type',
  },
  AddMasterProductKSM: {
    PATH: '/parameter-master-data/parameter/product-type/ksm/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Produk KSM',
  },
  DetailMasterProductKSM: {
    PATH: '/parameter-master-data/parameter/product-type/ksm/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Produk KSM',
  },
  DetailMasterProductKSMHistory: {
    PATH: '/parameter-master-data/parameter/product-type/ksm-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Produk KSM',
  },
  AddMasterCalculatorTht : {
    PATH: '/parameter-master-data/parameter/calculator/tht/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Parameter Kalkulator THT',
  },
  DetailMasterCalculatorTht : {
    PATH: '/parameter-master-data/parameter/calculator/tht/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Parameter Kalkulator THT',
  },
  UpdateMasterCalculatorTht : {
    PATH: '/parameter-master-data/parameter/calculator/tht/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Parameter Kalkulator THT',
  },
  ImportMasterCalculatorTht : {
    PATH: '/parameter-master-data/parameter/calculator/tht/import',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Import Parameter Kalkulator THT',
  },
  MasterDocument: {
    PATH: '/parameter-master-data/parameter/dokumen',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Dokumen',
  },
  AddMasterDocumentFollowup: {
    PATH: '/parameter-master-data/parameter/dokumen-follup/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Dokumen Follow-up',
  },
  DetailMasterDocumentFollowup: {
    PATH: '/parameter-master-data/parameter/dokumen-follup/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Dokumen Follow-up',
  },
  UpdateMasterDocumentFollowup: {
    PATH: '/parameter-master-data/parameter/dokumen-follup/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Dokumen Follow-up',
  },
  DetailMasterDocumentFollowupHistory: {
    PATH: '/parameter-master-data/parameter/dokumen-follup-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Dokumen Follow-up',
  },
  UpdateMasterDocumentFollowupHistory: {
    PATH: '/parameter-master-data/parameter/dokumen-follup-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Dokumen Follow-up',
  },
  AddMasterDocumentClosing: {
    PATH: '/parameter-master-data/parameter/dokumen-closing/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Dokumen Closing',
  },
  DetailMasterDocumentClosing: {
    PATH: '/parameter-master-data/parameter/dokumen-closing/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Dokumen Closing',
  },
  UpdateMasterDocumentClosing: {
    PATH: '/parameter-master-data/parameter/dokumen-closing/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Dokumen Closing',
  },
  DetailMasterDocumentClosingHistory: {
    PATH: '/parameter-master-data/parameter/dokumen-closing-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Dokumen Closing',
  },
  UpdateMasterDocumentClosingHistory: {
    PATH: '/parameter-master-data/parameter/dokumen-closing-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Dokumen Closing',
  },
  MasterAlamat: {
    PATH: '/parameter-master-data/parameter/alamat',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Alamat',
  },
  AddMasterAlamatProvinsi: {
    PATH: '/parameter-master-data/parameter/provinsi/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Alamat Provinsi',
  },
  DetailMasterAlamatProvinsi: {
    PATH: '/parameter-master-data/parameter/provinsi/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Alamat Provinsi',
  },
  UpdateMasterAlamatProvinsi: {
    PATH: '/parameter-master-data/parameter/provinsi/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Alamat Provinsi',
  },
  DetailMasterAlamatProvinsiHistory: {
    PATH: '/parameter-master-data/parameter/provinsi-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Alamat Provinsi',
  },
  UpdateMasterAlamatProvinsiHistory: {
    PATH: '/parameter-master-data/parameter/provinsi-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Alamat Provinsi',
  },
  AddMasterAlamatKota: {
    PATH: '/parameter-master-data/parameter/kota/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Alamat Kota',
  },
  DetailMasterAlamatKota: {
    PATH: '/parameter-master-data/parameter/kota/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Alamat Kota',
  },
  UpdateMasterAlamatKota: {
    PATH: '/parameter-master-data/parameter/kota/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Alamat Kota',
  },
  DetailMasterAlamatKotaHistory: {
    PATH: '/parameter-master-data/parameter/kota-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Alamat Kota',
  },
  UpdateMasterAlamatKotaHistory: {
    PATH: '/parameter-master-data/parameter/kota-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Alamat Kota',
  },
  AddMasterAlamatKecamatan: {
    PATH: '/parameter-master-data/parameter/kecamatan/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Alamat Kecamatan',
  },
  DetailMasterAlamatKecamatan: {
    PATH: '/parameter-master-data/parameter/kecamatan/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Alamat Kecamatan',
  },
  UpdateMasterAlamatKecamatan: {
    PATH: '/parameter-master-data/parameter/kecamatan/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Alamat Kecamatan',
  },
  DetailMasterAlamatKecamatanHistory: {
    PATH: '/parameter-master-data/parameter/kecamatan-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Alamat Kecamatan',
  },
  UpdateMasterAlamatKecamatanHistory: {
    PATH: '/parameter-master-data/parameter/kecamatan-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Alamat Kecamatan',
  },
  AddMasterAlamatKelurahan: {
    PATH: '/parameter-master-data/parameter/kelurahan/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Alamat Kelurahan',
  },
  DetailMasterAlamatKelurahan: {
    PATH: '/parameter-master-data/parameter/kelurahan/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Alamat Kelurahan',
  },
  UpdateMasterAlamatKelurahan: {
    PATH: '/parameter-master-data/parameter/kelurahan/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Alamat Kelurahan',
  },
  DetailMasterAlamatKelurahanHistory: {
    PATH: '/parameter-master-data/parameter/kelurahan-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Alamat Kelurahan',
  },
  UpdateMasterAlamatKelurahanHistory: {
    PATH: '/parameter-master-data/parameter/kelurahan-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Alamat Kelurahan',
  },
  AddMasterAlamatKodepos: {
    PATH: '/parameter-master-data/parameter/kodepos/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Alamat Kodepos',
  },
  DetailMasterAlamatKodepos: {
    PATH: '/parameter-master-data/parameter/kodepos/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Alamat Kodepos',
  },
  UpdateMasterAlamatKodepos: {
    PATH: '/parameter-master-data/parameter/kodepos/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Alamat Kodepos',
  },
  DetailMasterAlamatKodeposHistory: {
    PATH: '/parameter-master-data/parameter/kodepos-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Alamat Kodepos',
  },
  UpdateMasterAlamatKodeposHistory: {
    PATH: '/parameter-master-data/parameter/kodepos-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Alamat Kodepos',
  },
  MasterProspecting: {
    PATH: '/parameter-master-data/parameter/prospecting',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Prospecting',
  },
  AddMasterChannelProspecting: {
    PATH: '/parameter-master-data/parameter/prospecting-channel/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Channel Prospecting',
  },
  DetailMasterChannelProspecting: {
    PATH: '/parameter-master-data/parameter/prospecting-channel/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Channel Prospecting',
  },
  UpdateMasterChannelProspecting: {
    PATH: '/parameter-master-data/parameter/prospecting-channel/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Channel Prospecting',
  },
  DetailMasterChannelProspectingHistory: {
    PATH: '/parameter-master-data/parameter/prospecting-channel-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Channel Prospecting',
  },
  UpdateMasterChannelProspectingHistory: {
    PATH: '/parameter-master-data/parameter/prospecting-channel-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Channel Prospecting',
  },
  AddMasterTipeProspecting: {
    PATH: '/parameter-master-data/parameter/tipe-prospecting/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Tipe Prospecting',
  },
  DetailMasterTipeProspecting: {
    PATH: '/parameter-master-data/parameter/tipe-prospecting/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Tipe Prospecting',
  },
  UpdateMasterTipeProspecting: {
    PATH: '/parameter-master-data/parameter/tipe-prospecting/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Tipe Prospecting',
  },
  DetailMasterTipeProspectingHistory: {
    PATH: '/parameter-master-data/parameter/tipe-prospecting-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Tipe Prospecting',
  },
  UpdateMasterTipeProspectingHistory: {
    PATH: '/parameter-master-data/parameter/tipe-prospecting-history/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Revisi Master Tipe Prospecting',
  },
  AddMasterProductPinpen: {
    PATH: '/parameter-master-data/parameter/product-pinpen/add',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Tambah Master Product Pinpen',
  },
  DetailMasterProductPinpen: {
    PATH: '/parameter-master-data/parameter/product-pinpen/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Product Pinpen',
  },
  UpdateMasterProductPinpen: {
    PATH: '/parameter-master-data/parameter/product-pinpen/update',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Edit Master Product Pinpen',
  },
  DetailMasterProductPinpenHistory: {
    PATH: '/parameter-master-data/parameter/product-pinpen-history/detail',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Detail Master Product Pinpen',
  },
}

export const ParameterMasterDataTab = {
  ProductConfig: {
    PATH: '/parameter-master-data/parameter/product-config',
    TITLE: 'Product Configuration',
    TITLE_ALT: 'Product Configuration',
    LABEL: 'Product Configuration',
    SLUG: 'product-config',
  },
  BankConfig: {
    PATH: '/parameter-master-data/parameter/bank-config',
    TITLE: 'Bank Configuration',
    TITLE_ALT: 'Bank Configuration',
    LABEL: 'Bank Configuration',
    SLUG: 'bank-config',
  },
  BranchConfig: {
    PATH: '/parameter-master-data/parameter/branch-config',
    TITLE: 'Branch Configuration',
    TITLE_ALT: 'Branch Configuration',
    LABEL: 'Branch Configuration',
    SLUG: 'branch-config',
  },
  MenuConfig: {
    PATH: '/parameter-master-data/parameter/menu-config',
    TITLE: 'Menu Configuration',
    TITLE_ALT: 'Menu Configuration',
    LABEL: 'Menu Configuration',
    SLUG: 'menu-config',
  },
  MasterInsurance: {
    PATH: '/parameter-master-data/parameter/insurance',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Insurance',
    LABEL: 'Master Insurance',
    SLUG: 'master-insurance',
  },
  MasterCredit: {
    PATH: '/parameter-master-data/parameter/credit',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Credit',
    LABEL: 'Master Credit',
    SLUG: 'master-credit',
  },
  MasterDataDistribution: {
    PATH: '/parameter-master-data/parameter/dist',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Distribution',
    LABEL: 'Master Distribution',
    SLUG: 'master-distribution',
  },
  MasterDataDivision: {
    PATH: '/parameter-master-data/parameter/division',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Division',
    LABEL: 'Master Division',
    SLUG: 'master-division',
  },
  MasterDataReason: {
    PATH: '/parameter-master-data/parameter/reason',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Reason',
    LABEL: 'Master Reason',
    SLUG: 'master-reason',
  },
  MasterDataLeadsType: {
    PATH: '/parameter-master-data/parameter/leads-type',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Leads Type',
    LABEL: 'Master Leads Type',
    SLUG: 'master-leads-type',
  },
  MasterDataMitraBankBayar: {
    PATH: '/parameter-master-data/parameter/mitra-bank-bayar',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Mitra Kantor Bayar',
    LABEL: 'Mitra Kantor Bayar',
    SLUG: 'master-mitra-bank-bayar',
  },
  MasterCalculator : {
    PATH: '/parameter-master-data/parameter/calculator',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Parameter Calculator',
    LABEL: 'Parameter Calculator',
    SLUG: 'master-calculator',
  },
  MasterProductType : {
    PATH: '/parameter-master-data/parameter/product-type',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Paramater Product',
    LABEL: 'Parameter Product',
    SLUG: 'parameter-product'
  },
  MasterDocument : {
    PATH: '/parameter-master-data/parameter/dokumen',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Document',
    LABEL: 'Master Document',
    SLUG: 'master-document'
  },
  MasterAlamat : {
    PATH: '/parameter-master-data/parameter/alamat',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Alamat',
    LABEL: 'Master Alamat',
    SLUG: 'master-alamat'
  },
  MasterProspecting : {
    PATH: '/parameter-master-data/parameter/prospecting',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Master Prospecting',
    LABEL: 'Master Prospecting',
    SLUG: 'master-prospecting'
  }
}

export default ParameterMasterDataScreens

const MonitoringScreens = {
  Monitoring: {
    PATH: '/monitoring',
    TITLE: 'Monitoring',
    TITLE_ALT: 'Monitoring',
    PERMISSION: '',
  },
  AuditTrail: {
    PATH: '/monitoring/audit-trail',
    TITLE: 'Audit Trail',
    TITLE_ALT: 'Audit Trail',
    PERMISSION: 'DASHBOARD_AUDIT_TRAIL.read',
  },
  SALES_ACTIVITY: {
    PATH: '/monitoring/sales-activity/:pos',
    TITLE: 'Monitoring',
    TITLE_ALT: 'Sales Activity',
    PERMISSION: 'MONITORING_SALES_ACTIVITY.read',
  },
  GEOTRACKING_GEOTAGGING: {
    PATH: '/monitoring/geotracking/:pos',
    TITLE: 'Monitoring',
    TITLE_ALT: 'Geotracking & Geotagging',
    PERMISSION: 'MONITOR_GEOTAGGING.read',
  },
  GEOTRACKING_GEOTAGGING_DETAIL: {
    PATH: '/monitoring/geotracking/:pos/detail/:id/:name',
    TITLE: 'Monitoring',
    TITLE_ALT: 'Geotracking & Geotagging',
    PERMISSION: 'MONITOR_GEOTAGGING.read',
  },
}

export default MonitoringScreens

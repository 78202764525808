import axios from 'axios'
import {BaseResponse, Pagination, QueryModel, Response} from 'src/app/models/api.types'
import {ISaveRole, Role, RoleAccessTemplate, RoleDetail} from '../models/Role.model'
import {User, UserProfile, UserQuery} from '../models/User.model'

// NEW URL
export const FORGET_PASSWORD = `/api/web/forget-password`
export const CHANGE_PASSWORD = `/api/web/change-password`

// OLD URL
// API URL
export const MENU = '/api/web/menu'
export const USER = '/api/v1/admins/users'
export const ROLE = '/api/v1/admins/user-roles'
export const ROLE_ACCESS_TEMPLATE = '/api/v1/admins/user-roles/access-template'

// REACT QUERY KEY
export const LIST_USER_KEY = 'list-user'
export const DETAIL_USER_KEY = 'detail-user'
export const LIST_ROLE_KEY = 'list-role'
export const DETAIL_ROLE_KEY = 'detail-role'
export const ACCESS_TEMPLATE = 'access-template'

// User API
export const getListUser = (params?: UserQuery) => {
  return axios.get<BaseResponse<Pagination<User[]>>>(USER, {
    params,
  })
}

export const getDetailUser = (id?: string) => {
  return axios.get<BaseResponse<Response<User>>>(`${USER}/${id}`)
}

export const saveUser = (data?: User, id?: string) => {
  if (id) return axios.put(`${USER}/${id}`, data)

  return axios.post(USER, data)
}

export const deleteUser = (id?: string, data?: any) => {
  return axios.delete(`${USER}/${id}`, {
    data,
  })
}

export const verificationUserToken = (data?: any) => {
  return axios.post(`${USER}/verification-token`, data)
}

export const resetPasswordUser = (data?: any, headers?: any) => {
  return axios.post(`${CHANGE_PASSWORD}`, data, {
    headers,
  })
}

export const forgotPassword = (data?: any, headers?: any) => {
  return axios.post(`${FORGET_PASSWORD}`, data, {
    headers,
  })
}

export const getProfile = () => {
  return axios.get<BaseResponse<Response<UserProfile>>>(`${MENU}`)
}

// Role API

export const getListRole = (params?: QueryModel) => {
  return axios.get<BaseResponse<Pagination<Role[]>>>(ROLE, {
    params,
  })
}

export const getDetailRole = (id?: string) => {
  return axios.get<BaseResponse<Response<RoleDetail>>>(`${ROLE}/${id}`)
}

export const saveRole = (data?: ISaveRole, id?: string) => {
  if (id) return axios.put(`${ROLE}/${id}`, data)

  return axios.post(ROLE, data)
}

export const deleteRole = (id?: string) => {
  return axios.delete(`${ROLE}/${id}`)
}

export const getListAccessTemplateRole = () => {
  return axios.get<BaseResponse<Response<RoleAccessTemplate[]>>>(ROLE_ACCESS_TEMPLATE)
}

export const forgetPassword = (nip: string) => {
  return axios.post(`${FORGET_PASSWORD}`, {nip})
}

const HomeScreens = {
  Home: {
    PATH: '/home',
    TITLE: 'Home',
    TITLE_ALT: 'Home',
    PERMISSION: '',
  },
  AkunSaya: {
    PATH: '/akun-saya',
    TITLE: 'Akun Saya',
    TITLE_ALT: 'Akun Saya',
    PERMISSION: '',
  }
}

export default HomeScreens

import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const FaqScreens = {
  FAQ: {
    PATH: '/faq',
    TITLE: 'FAQ',
    TITLE_ALT: 'FAQ',
  },
  LIST_FAQ_CATEGORY: {
    PATH: '/faq/faq-category/list',
    HEADER_NAME: 'FAQ Category',
    TITLE: 'FAQ Category',
    TITLE_ALT: 'FAQ Category',
  },
  ADD_FAQ_CATEGORY: {
    PATH: '/faq/faq-category/list/add',
    TITLE: 'Add Category',
    TITLE_ALT: 'Add Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_FAQ_CATEGORY: {
    PATH: '/faq/faq-category/list/detail/:id',
    TITLE: 'Detail Category',
    TITLE_ALT: 'Detail Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_FAQ_CATEGORY: {
    PATH: '/faq/faq-category/list/edit/:id',
    TITLE: 'Edit Category',
    TITLE_ALT: 'Edit Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_FAQ_CONTENT: {
    PATH: '/faq/faq-content/list',
    HEADER_NAME: 'FAQ Content',
    TITLE: 'FAQ Content',
    TITLE_ALT: 'FAQ Content',
  },
  ADD_FAQ_CONTENT: {
    PATH: '/faq/faq-content/list/add',
    TITLE: 'Add Content',
    TITLE_ALT: 'Add Content',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_FAQ_CONTENT: {
    PATH: '/faq/faq-content/list/detail/:id',
    TITLE: 'Detail Content',
    TITLE_ALT: 'Detail Content',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_FAQ_CONTENT: {
    PATH: '/faq/faq-content/list/edit/:id',
    TITLE: 'Edit Content',
    TITLE_ALT: 'Edit Content',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export const FaqTab = {
  SUMMARY: {
    PATH: '/faq/faq-category/list',
    TITLE: 'Category',
    TITLE_ALT: 'Category',
    LABEL: 'Category',
    SLUG: 'category',
  },
  PROFILE_LOG: {
    PATH: '/faq/faq-content/list',
    TITLE: 'Content',
    TITLE_ALT: 'Content',
    LABEL: 'Content',
    SLUG: 'content',
  },
}

export default FaqScreens

const DashboardScreens = {
  Dashboard: {
    PATH: '/dashboard',
    TITLE: 'Dashboard',
    TITLE_ALT: 'Dashboard',
    PERMISSION: 'REPORT_AKTIVITAS_PENGGUNAAN_LMS.read',
  },

  UserActivityLeads: {
    PATH: '/dashboard/user-activity-leads',
    TITLE: 'Report Aktivitas Penggunaan LMS',
    TITLE_ALT: 'Report Aktivitas Penggunaan LMS',
    PERMISSION: 'REPORT_AKTIVITAS_PENGGUNAAN_LMS.read',
  },
}

export default DashboardScreens

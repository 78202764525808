import {FormSelect, GButton} from '../../Libs'
interface ReportFilterProps {
  month?: any
  onChangeMonth?: any
  year?: any
  onChangeYear?: any
  onApply?: any
  buttonDisabled: boolean
}

const ReportFilter = ({
  month,
  onChangeMonth,
  year,
  onChangeYear,
  onApply,
  buttonDisabled,
}: ReportFilterProps) => {
  const monthNames = Array.from({length: 12}, (v, i) => ({
    label: new Date(0, i).toLocaleString('id-ID', {month: 'long'}),
    value: new Date(0, i).toLocaleString('default', {month: '2-digit'}),
  }))

  const yearsArray = []
  for (let year = new Date().getFullYear(); year >= 2000; year--) {
    yearsArray.push({label: year.toString(), value: year.toString()})
  }

  return (
    <div className='flex w-screen-layout bg-white -ml-8 -mt-8 py-8 pl-8 mb-8 gap-4 z-20 relative'>
      <div className='flex flex-col'>
        <span className='font-bold mb-2'>Bulan</span>
        <FormSelect
          options={monthNames}
          placeholder='Pilih Bulan'
          title='Bulan'
          className='bg-white rounded-lg w-44'
          selectedValue={month}
          changedValue={onChangeMonth}
        />
      </div>
      <div className='flex flex-col'>
        <span className='font-bold mb-2'>Tahun</span>
        <FormSelect
          options={yearsArray}
          placeholder='Pilih Tahun'
          title='Tahun'
          className='bg-white rounded-lg w-44'
          color=''
          selectedValue={year}
          changedValue={onChangeYear}
        />
      </div>
      <div className='flex flex-col justify-end pb-1'>
        <GButton onClick={onApply} type='submit' disabled={buttonDisabled}>
          Terapkan
        </GButton>
      </div>
    </div>
  )
}

export default ReportFilter

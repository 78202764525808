const MailboxScreens = {
  Mailbox: {
    PATH: '/mailbox',
    TITLE: 'Mailbox',
    TITLE_ALT: 'Mailbox',
    PERMISSION: 'MAILBOX.read',
  },
  MailboxParameter: {
    PATH: '/parameter/broadcaster-mailbox',
    TITLE: 'Parameter & Master Data',
    TITLE_ALT: 'Mailbox Broadcaster',
    PERMISSION: 'PESAN_BROADCAST.read',
  },
  MailboxParameterDetail: {
    PATH: '/parameter/broadcaster-mailbox/detail/:id',
    TITLE: 'Detail Pesan',
    TITLE_ALT: 'Mailbox Broadcaster',
    PERMISSION: 'PESAN_BROADCAST.read',
    PERMISSION_APPROVE: 'PESAN_BROADCAST.approve',
  },
  AddMailboxParameterDetail: {
    PATH: '/parameter/broadcaster-mailbox/add-mail',
    TITLE: 'Parameter & Master Data',
    PERMISSION: 'PESAN_BROADCAST.insert_update',
    TITLE_ALT: 'Tambah Pesan'
  },
  MailboxDetail: {
    PATH: '/mailbox/detail/:id',
    TITLE: 'Detail Pesan',
    TITLE_ALT: 'Mailbox Detail',
    PERMISSION: 'MAILBOX.read',
  },
}

export default MailboxScreens

/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from 'src/app/components/Layout/header/HeaderTitle'
import {useWebTitle} from 'src/app/hooks/title-hook'
import DashboardScreens from '../Screens'
import LoadingScreen from 'src/app/components/Widgets/LoadingScreen'
import {DashboardBreadcrumb} from '../DashboardRoutes'
import {Card, GModal} from 'src/app/components/Libs'
import imageUtils from 'src/app/utils/image-utils'
import {Tooltip} from 'react-tooltip'
import clsx from 'clsx'
import GIcon from 'src/app/components/Libs/Icon/GIcon'
import {DashboardDataModel, SalesFeedback} from './models/Dashboard.models'
import ReportFilter from 'src/app/components/Widgets/ReportFilter/ReportFilter'
import {v4 as uuidv4} from 'uuid'
import {positionEnum, User} from '../../user-role-management/models/User.model'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {getDashboard} from '../services/Dashboard.services'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import responseUtils from 'src/app/utils/response-utils'
import {useHeaderToast} from 'src/app/context/ToastContext'
import {conditionChecker} from 'src/app/components/Widgets/Stepper/helper'
import { getErrorMessage } from 'src/app/utils/api-utils'
require('dayjs/locale/id')

const DashboardPage: FC = () => {
  useWebTitle(DashboardScreens.Dashboard.TITLE)
  const [loading, setLoading] = useState<boolean>(false)
  const [showPopupReason, setShowPopupReason] = useState<boolean>(false)
  const [selectedFeedback, setSelectedFeedback] = useState<SalesFeedback>()
  const [dashboardData, setDashboardData] = useState<DashboardDataModel>()
  const [hasilKunjungan, setHasilKunjungan] = useState<any[]>([])
  const [salesFeedback, setSalesFeedback] = useState<any[]>([])
  const [monthLabel, setMonthLabel] = useState<string>('')
  const [month, setMonth] = useState<any>('')
  const [year, setYear] = useState<any>('')
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false)
  const BgDashboard = imageUtils.BgHomeWave3
  const user: User = useSelector<RootState>(({auth}) => auth?.user, shallowEqual) as User
  const {addPageToasts} = useHeaderToast()

  function getEdgeOfMonth(year: any, month: any) {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    const first_date = dayjs(`${year}-${month}-01`).startOf('month').format('YYYY-MM-DD')
    const last_date = dayjs(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD')
    const offSet = new Date().getTimezoneOffset() / -60
    const start_date = encodeURIComponent(`${first_date}T00:00:00.000+0${offSet}:00`)
    const end_date = encodeURIComponent(`${last_date}T23:59:59.999+0${offSet}:00`)

    return {start_date, end_date}
  }

  const fetchDashboardData = (position: positionEnum) => {
    const {start_date, end_date} = getEdgeOfMonth(year, month)
    setLoading(true)
    getDashboard(position, {start_date, end_date})
      .then((res) => {
        if (res.data.response_schema?.response_code === responseUtils.RESPONSE_CODE.SUCCESS) {
          setDashboardData(res.data.response_output?.detail)
          setHasilKunjungan([
            {
              name: 'Total Setuju',
              color: 'success-500',
              value: `${res.data.response_output?.detail?.result_visit.total_setuju}%`,
              borderColor: 'border-l-success-500',
            },
            {
              name: 'Total Ragu-Ragu',
              color: 'signature-600',
              borderColor: 'border-l-signature-600',
              value: `${res.data.response_output?.detail?.result_visit.total_ragu_ragu ?? '-'}%`,
            },
            {
              name: 'Total Tolak',
              color: 'danger-500',
              value: `${res.data.response_output?.detail?.result_visit.total_tolak ?? '-'}%`,
              borderColor: 'border-l-danger-500',
            },
            {
              name: 'Total Meninggal',
              borderColor: 'border-l-neutral-500',
              value: `${res.data.response_output?.detail?.result_visit.total_meninggal ?? '-'}%`,
              color: 'neutral-600',
            },
            {
              name: 'Total Alamat Tidak Ditemukan',
              borderColor: 'border-l-neutral-500',
              value: `${res.data.response_output?.detail?.result_visit.total_alamat_tidak_ditemukan ?? '-'}%`,
              color: 'neutral-600',
            },
            {
              name: 'Total Tidak Ditempat',
              borderColor: 'border-l-neutral-500',
              value: `${res.data.response_output?.detail?.result_visit.total_tidak_ditempat ?? '-'}%`,
              color: 'neutral-600',
            },
            {
              name: 'Total Belum Kunjungan',
              borderColor: 'border-l-primary-600',
              color: 'primary-600',
              value: `${res.data.response_output?.detail?.result_visit.total_not_visit ?? '-'}%`,
            },
          ])
          setSalesFeedback([
            {
              name: 'Setuju',
              value: `${res.data.response_output?.detail?.sales_feedback.setuju.percent}%`,
              color: 'success-500',
              desc: res.data.response_output?.detail?.sales_feedback.setuju.detail,
            },
            {
              name: 'Ragu-ragu',
              value: `${res.data.response_output?.detail?.sales_feedback.ragu_ragu.percent}%`,
              color: 'signature-600',
              desc: res.data.response_output?.detail?.sales_feedback.ragu_ragu.detail,
            },
            {
              name: 'Tolak',
              value: `${res.data.response_output?.detail?.sales_feedback.tolak.percent ?? '-'}%`,
              color: 'danger-500',
              desc: res.data.response_output?.detail?.sales_feedback.tolak.detail,
            },
          ])
          setMonthLabel(`${dayjs(month, 'MM').locale('ID').format('MMMM')} ${year}`)
          setButtonDisabled(true)
        } else {
          addPageToasts({scheme: 'danger', text: res.data.response_schema?.response_message + ''})
        }
      })
      .catch((err) => {
        addPageToasts({scheme: 'danger', text: getErrorMessage(err, true)})
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const positionTitle = (pos: any) => {
    switch (pos) {
      case 'HO':
        return 'Distribution Head'
      case 'DH':
        return 'KC'
      case 'KC':
        return 'KCP'
      case 'KCP':
        return 'AOP'

      default:
        return ''
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (
    <div>
      <LoadingScreen isloading={loading} />
      <PageTitle breadcrumbs={DashboardBreadcrumb}>Dashboard</PageTitle>
      <ReportFilter
        year={year}
        month={month}
        onChangeMonth={(e: any) => {
          setMonth(e.value)
          setButtonDisabled(false)
        }}
        onChangeYear={(e: any) => {
          setYear(e.value)
          setButtonDisabled(false)
        }}
        onApply={() => {
          fetchDashboardData(user.position!)
        }}
        buttonDisabled={isButtonDisabled}
      />
      <GModal show={showPopupReason} onHide={() => setShowPopupReason(false)}>
        <div className='border-b border-neutral-40 w-full p-4 flex flex-row justify-between'>
          <Card.HeaderTitle>{`List Alasan ${selectedFeedback?.name}`}</Card.HeaderTitle>
          <GIcon
            icon='IconClose'
            className='h-6 w-6 align-middle hover:cursor-pointer'
            onClick={() => setShowPopupReason(false)}
          />
        </div>

        <Card.Body className='p-4 w-full'>
          {selectedFeedback?.desc
            ?.sort((a, b) => b?.total - a?.total)
            .map((value: any) => (
              <div key={uuidv4()} className='flex py-2 border-b border-b-gray-300 w-full'>
                <span>{`"${value?.alasan}" (${value?.total})`}</span>
              </div>
            ))}
        </Card.Body>
      </GModal>
      <div className={Boolean(dashboardData) ? 'visible' : 'hidden'}>
        <Card.Root>
          <Card.Header>
            <div className='py-4 -ml-4'>
              <span className='font-bold pb-2 text-lg border-primary'>Summary Dashboard</span>
              <h5 className='text-neutral-70'>{`Menampilkan data periode bulan ${monthLabel}`}</h5>
            </div>
          </Card.Header>
          <div className='pb-2'>
            <div className='bg-signature-100 p-4 overflow-hidden'>
              <div className='relative py-4'>
                <img
                  src={BgDashboard}
                  alt='profile-pic'
                  className='absolute overflow-hidden -top-4 -right-4 z-1'
                />
                <h4 className='font-bold mb-4 text-xl'>{positionTitle(user?.position)}</h4>
                <div className='grid grid-cols-5 gap-4 '>
                  {dashboardData?.distribution_data.map((val) => (
                    <div key={uuidv4()} className='bg-white rounded-lg p-4 z-10'>
                      <h5 className='mb-2 text-lg'>{val.title}</h5>
                      <h4 className='text-blue-700 text-2xl font-bold'>{val.value}</h4>
                      <h5 className='text-neutral-500'>Total Database</h5>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='grid grid-cols-2 gap-4 mt-2 p-4'>
              <div className='border-l-4 border-l-primary-600 bg-white rounded-lg border border-neutral-60 px-4 py-4'>
                <h5 className='pb-2'>
                  Total Aktivitas Distribusi Database oleh ROP
                  <span className='tooltip-anchor cursor-pointer rounded-full border px-[6px] ml-2 border-black text-center text-[10pt]'>
                    !
                  </span>
                </h5>
                <Tooltip
                  data-testid='v-tooltip-test'
                  anchorSelect='.tooltip-anchor'
                  id='tooltip-status'
                  place='bottom'
                  className={clsx('rounded-lg shadow-lg', 'bg-neutral-800 text-white z-50')}
                >
                  Rata-rata ROP menggunakan program aplikasi dalam mendisitribusikan database.
                </Tooltip>
                <h4 className='text-blue-700 text-2xl font-bold'>{`${
                  dashboardData?.total_activity_database_distribution_from_rop ?? '-'
                }%`}</h4>
              </div>
              <div className='border-l-4 border-l-primary-300 bg-white rounded-lg border border-neutral-60 px-4 py-4'>
                <h5 className='pb-2'>
                  Total Aktivitas Kunjungan AOP
                  <span className='aop cursor-pointer rounded-full border px-[6px] ml-2 border-black text-center text-[10pt]'>
                    !
                  </span>
                </h5>
                <Tooltip
                  data-testid='aop'
                  anchorSelect='.aop'
                  id='aop'
                  place='bottom'
                  className={clsx('rounded-lg shadow-lg', 'bg-neutral-800 text-white z-50')}
                >
                  Hasil kunjungan dari database yang 'berhasil' dikunjungi oleh AOP
                </Tooltip>
                <h4 className='text-blue-700 text-2xl font-bold'>{`${
                  dashboardData?.total_activity_visit_aop ?? '-'
                }%`}</h4>
              </div>
            </div>
            <div className='grid grid-cols-2 gap-4 py-2 px-4'>
              <div>
                <div className='border-l-4 border-l-[#A49769] bg-white rounded-lg border border-neutral-60 px-2 py-4'>
                  <h5 className='pb-4'>Efektivitas</h5>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='bg-[#FAF8E8] rounded-lg p-2'>
                      <h3 className='text-[#766434] font-bold text-2xl'>{`${
                        dashboardData?.effectivity.total_effectivity ?? '-'
                      }%`}</h3>
                      <h5 className='pb-2 text-neutral-70'>Total Efektivitas</h5>
                    </div>
                    <div className='bg-[#FAF8E8] rounded-lg p-2'>
                      <h3 className='text-[#766434] font-bold text-2xl'>{`${
                        dashboardData?.effectivity.total_noa ?? '-'
                      }%`}</h3>
                      <h5 className='pb-2 text-neutral-70'>Total NoA</h5>
                    </div>
                  </div>
                  <div className='bg-[#FAF8E8] rounded-lg p-2 mt-4'>
                    <h3 className='text-[#766434] font-bold text-2xl'>{`${
                      dashboardData?.effectivity.total_visit_result ?? '-'
                    }`}</h3>
                    <h5 className='pb-2 text-neutral-70'>
                      Total Laporan Hasil Kunjungan (Database)
                    </h5>
                  </div>
                </div>
                <div className='border-l-4 border-l-[#A49769] bg-white rounded-lg border border-neutral-60 px-2 py-4 mt-4'>
                  <h5 className='pb-4'>Produktivitas</h5>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='bg-[#FAF8E8] rounded-lg p-2'>
                      <h3 className='text-[#766434] font-bold text-2xl'>{`${
                        dashboardData?.productivity.total_productivity ?? '-'
                      }%`}</h3>
                      <h5 className='pb-2 text-neutral-70'>Total Produktivitas</h5>
                    </div>
                    <div className='bg-[#FAF8E8] rounded-lg p-2'>
                      <h3 className='text-[#766434] font-bold text-2xl'>{`${
                        dashboardData?.productivity.total_noa ?? '-'
                      }`}</h3>
                      <h5 className='pb-2 text-neutral-70'>Total NoA</h5>
                    </div>
                  </div>
                  <div className='bg-[#FAF8E8] rounded-lg p-2 mt-4'>
                    <h3 className='text-[#766434] font-bold text-2xl'>{`${
                      dashboardData?.productivity.total_visit_result_plus_not_visit ?? '-'
                    }`}</h3>
                    <h5 className='pb-2 text-neutral-70'>
                      Total Laporan Hasil Kunjungan + Belum Kunjungan (Database)
                    </h5>
                  </div>
                </div>
              </div>
              <div className='border-l-4 border-l-[#A49769] bg-bg-[#FAF8E8] rounded-lg border border-neutral-60 px-2 py-4 h-fit'>
                <h5 className='pb-4'>Data Potensi</h5>
                <div className='grid grid-cols-2 gap-4'>
                  <div className='bg-[#FAF8E8] rounded-lg p-2'>
                    <h3 className='text-[#766434] font-bold text-2xl'>{`${
                      dashboardData?.data_potential.total_noa ?? '-'
                    }`}</h3>
                    <h5 className='pb-2 text-neutral-70'>Total NoA</h5>
                  </div>
                  <div className='bg-[#FAF8E8] rounded-lg p-2'>
                    <h3 className='text-[#766434] font-bold text-2xl'>{`${
                      dashboardData?.data_potential.total_plafond ?? '-'
                    }`}</h3>
                    <h5 className='pb-2 text-neutral-70'>Total Plafond</h5>
                  </div>
                </div>
                <div className='w-full border-b border-dashed border-neutral-70 py-2 mb-4' />
                <h5 className='text-neutral-70 pb-2 text-sm'>Komposisi Data</h5>
                <div className='grid grid-cols-2 grid-flow-row gap-4'>
                  <div className='bg-[#FAF8E8] rounded-lg p-2'>
                    <h3 className='text-[#766434] font-bold text-2xl'>{`${
                      dashboardData?.data_potential.komposisi_data_pens ?? '-'
                    }%`}</h3>
                    <h5 className='pb-2 text-neutral-70'>Total Pensiun</h5>
                  </div>
                  <div className='bg-[#FAF8E8] rounded-lg p-2'>
                    <h3 className='text-[#766434] font-bold text-2xl'>{`${
                      dashboardData?.data_potential.komposisi_data_bup ?? '-'
                    }%`}</h3>
                    <h5 className='pb-2 text-neutral-70'>Total BUP</h5>
                  </div>
                  <div className='bg-[#FAF8E8] rounded-lg p-2'>
                    <h3 className='text-[#766434] font-bold text-2xl'>{`${
                      dashboardData?.data_potential.komposisi_data_dapem ?? '-'
                    }%`}</h3>
                    <h5 className='pb-2 text-neutral-70'>Total Dapem</h5>
                  </div>
                  <div className='bg-[#FAF8E8] rounded-lg p-2'>
                    <h3 className='text-[#766434] font-bold text-2xl'>{`${
                      dashboardData?.data_potential.komposisi_data_topup ?? '-'
                    }%`}</h3>
                    <h5 className='pb-2 text-neutral-70'>Total Topup</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Root>

        <Card.Root className='mt-4'>
          <Card.Header>
            <div className='py-4'>
              <h4 className='font-bold pb-4 text-xl'>Hasil Kunjungan</h4>
              <h5 className='text-neutral-70'>{`Menampilkan data periode bulan ${monthLabel}`}</h5>
            </div>
          </Card.Header>
          <Card.Body>
            <div className='grid grid-cols-5 gap-4'>
              {hasilKunjungan.map((i: any) => (
                <div
                  key={uuidv4()}
                  className={`border border-l-4 ${i?.borderColor} bg-white rounded-lg  border-neutral-60 px-2 py-4`}
                >
                  <h5>{i?.name}</h5>
                  <span
                    style={{color: conditionChecker(i?.color === '', '#475467', '')}}
                    className={`font-bold text-2xl ${conditionChecker(
                      i?.color === '',
                      '',
                      `text-${i?.color}`
                    )}`}
                  >
                    {parseFloat(i?.value).toFixed(2) + '%'}
                  </span>
                </div>
              ))}
            </div>
          </Card.Body>
        </Card.Root>

        <Card.Root className='mt-4'>
          <Card.Header>
            <div className='py-4'>
              <h4 className='font-bold text-xl'>Sales Feedback</h4>
              <h5 className='text-neutral-70'>{`Menampilkan data periode bulan ${monthLabel}`}</h5>
            </div>
          </Card.Header>
          <Card.Body>
            <div className='grid grid-cols-2 gap-4'>
              {salesFeedback.map((feedback: any) => (
                <div
                  key={uuidv4()}
                  className={`border-l-4 border-l-${feedback?.color} border bg-white rounded-lg  border-neutral-60 px-2 py-4 flex flex-row`}
                >
                  <div className='w-1/4'>
                    <h5>{`Total ${feedback?.name}`}</h5>
                    <span className={`font-bold text-2xl text-${feedback.color}`}>
                      {`${parseFloat(feedback?.value).toFixed(2)}%`}
                    </span>
                  </div>
                  <div className='w-3/4 border-l-2 flex flex-col justify-start gap-2 border-l-neutral-60 border-dashed pl-4 ml-2'>
                    <span className='text-lg'>Alasan</span>
                    <div className='rounded-lg bg-[#E3F5FC] p-2'>
                      {feedback.desc
                        ?.sort((a: any, b: any) => b?.total - a?.total)
                        .slice(0, 3)
                        .map((val: any) => (
                          <div key={uuidv4()} className='w-full'>
                            <span className='text-sm text-neutral-70'>{`"${val?.alasan}" (${val?.total})`}</span>
                          </div>
                        ))}
                    </div>
                    <div
                      className={`flex flex-row ${
                        feedback.desc?.length > 3 ? 'visible' : 'hidden'
                      }`}
                    >
                      <span
                        className='text-signature-2-600 hover:text-signature cursor-pointer'
                        onClick={() => {
                          setShowPopupReason(true)
                          setSelectedFeedback(feedback)
                        }}
                      >
                        Lihat Semua Alasan
                      </span>
                      <GIcon icon='ArrowRightSignature' className='h-6 w-6' />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Card.Body>
        </Card.Root>
      </div>
    </div>
  )
}

export default DashboardPage

const PipelineDistributionScreens = {
  PipelineDistribution: {
    PATH: '/distribusi-leads',
    TITLE: 'Distribusi Leads',
    TITLE_ALT: 'Distribusi Leads',
    PERMISSION: 'DISTRIBUSI_NEW_LEADS.read',
  },
  NewPipelineDistribution: {
    PATH: '/distribusi-leads/new-data',
    TITLE: 'Distribusi Leads',
    TITLE_ALT: 'New Data Leads',
    PERMISSION: 'DISTRIBUSI_NEW_LEADS.read',
  },
  AddNewPipelineDistribution: {
    PATH: '/distribusi-leads/new-data/add',
    TITLE: 'Distribusi Leads',
    TITLE_ALT: 'Add New Data Leads',
    PERMISSION: 'DISTRIBUSI_NEW_LEADS.insert_update',
  },
  ExistingPipelineDistribution: {
    PATH: '/distribusi-leads/existing-pipeline',
    TITLE: 'Distribusi Leads',
    TITLE_ALT: 'Existing Data Leads',
    PERMISSION: 'DISTRIBUSI_EXISTING_LEADS.read',
  },
  AddExistingPipelineDistribution: {
    PATH: '/distribusi-leads/existing-pipeline/add',
    TITLE: 'Distribusi Leads',
    TITLE_ALT: 'Existing Data Leads',
    PERMISSION: 'DISTRIBUSI_EXISTING_LEADS.insert_update',
  },
}

export default PipelineDistributionScreens

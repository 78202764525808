const BlackBoxScreens = {
  BlackBox: {
    PATH: '/blackbox',
    TITLE: 'Black Box',
    TITLE_ALT: 'Black Box',
    PERMISSION: 'BLACK_BOX.read',
  },
  BlackBoxDetail: {
    PATH: '/blackbox/detail/:id',
    TITLE: 'Black Box',
    TITLE_ALT: 'Detail Data Nasabah',
    PERMISSION: 'BLACK_BOX.read',
  },
  BlackBoxDetailBreadcrumbs: [
    {
      isActive: false,
      path: '/blackbox',
      title: 'Blackbox',
    },
    {isActive: false, path: '', title: '', isSeparator: true},
  ],
}

export default BlackBoxScreens

import {useCallback, useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from 'src/setup'
import {ModuleMenu, ModulePermissionQuery, RoleMenu} from '../modules/permissions/models/Role.model'

export function usePermissions(pageQuery?: ModulePermissionQuery) {
  const history = useHistory()
  const permissions = useSelector<RootState>(
    ({auth}) => { return mapMenus(auth)},
    shallowEqual
  ) as ModulePermissionQuery[]

  function mapMenus(auth: any): string[] {
    const menus: string[] = []
    auth.user?.menu?.forEach((e: RoleMenu) => {
      if(e.can_approve) menus.push(String(e.menu_code).concat('.', 'approve'))
      if(e.can_delete) menus.push(String(e.menu_code).concat('.', 'delete'))
      if(e.can_insert_update) menus.push(String(e.menu_code).concat('.', 'insert_update'))
      if(e.can_read) menus.push(String(e.menu_code).concat('.', 'read'))
    })
    
    const sideMenus: string[] = [];
    ModuleMenu.forEach((e: any) => {
      for(const menu_code of e?.combinations || []) {
        const opt = menus.find(menu => String(menu).startsWith(menu_code))
        if(opt) {
          sideMenus.push(String(e?.menu_code).concat('.', 'read'))
          return
        } 
      }
    })
    return [...menus, ...sideMenus];
  }

  const hasAccess = useCallback(
    (q?: ModulePermissionQuery) => {
      const code = q ?? pageQuery
      if (!code) return true
      return Boolean(permissions?.find((data) => data === (q ?? pageQuery ?? '')))
    },
    [pageQuery, permissions]
  )

  const canAccessPage = useMemo(() => hasAccess(), [hasAccess])

  const accessRedirect = useCallback(
    (q?: ModulePermissionQuery, path?: string) => {
      if (q) {
        if (canAccessPage && hasAccess(q)) {
          return
        }
        if (path) return history.push(path)
        return history.goBack()
      }
    },
    [canAccessPage, hasAccess, history]
  )
  return {hasAccess, canAccessPage, accessRedirect}
}

import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {FormLabel, FormPassword, FormText, GAlert, GButton} from 'src/app/components/Libs'
import {useWebTitle} from 'src/app/hooks/title-hook'
import * as Yup from 'yup'
import AuthScreens from '../../Screens'
import AuthRedux from '../../redux/AuthRedux'
import {generateCaptcha, loginByEmail} from '../../services/Auth.services'
import GIcon from '../../../../components/Libs/Icon/GIcon'
import wordingUtils from 'src/app/utils/wording-utils'
import {v4 as uuidv4} from 'uuid'
import responseUtils from 'src/app/utils/response-utils'
import { useOnline } from 'src/app/hooks/online-hook'
import { showToast } from 'src/app/components/Widgets/Toast'

const loginSchema = Yup.object().shape({
  nip: Yup.string().required(),
  password: Yup.string().required(),
  captcha_value: Yup.string().required(),
})

const initialValues = {
  nip: '',
  password: '',
  captcha_value: '',
  captcha_uid: '',
}

const LoginEmail: FC = () => {
  const [toastMessage, setToastMessage] = useState('')
  const [captchaUid, setCaptchaUid] = useState('')
  const [imgCaptcha, setImgCaptcha] = useState<string>()
  const [loadingCaptcha, setLoadingCaptcha] = useState<boolean>(false)
  const [isFormEmpty, setFormEmpty] = useState<boolean>(false)
  const dispatch = useDispatch()
  const history = useHistory()

  useWebTitle('Login')

  useEffect(() => {
    getImgCaptcha(uuidv4())
  }, [])

  const getImgCaptcha = (uuid: string) => {
    setLoadingCaptcha(true)
    generateCaptcha(uuid)
      .then((res) => {
        setImgCaptcha(URL.createObjectURL(res.data))
        setCaptchaUid(uuid)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoadingCaptcha(false)
      })
  }

  const {isOnline} = useOnline()
  const handleOffline = useCallback(()=>{
    if(!isOnline) { 
      setTimeout(()=>{
        setToastMessage(wordingUtils.ErrorMessage.userOffline)
        setTimeout(() => {
          setToastMessage('')
        }, 3000)
      }, 500)
    }
  }, [isOnline])
  
  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      payload.captcha_uid = captchaUid
      return loginByEmail(payload)
    },
    onSuccess: (result) => {
      const data = result.data.response_output?.detail
      const {access_token, refresh_token, change_password}: any = data
      if (change_password) {
        const params = {
          access_token,
          old_password: formik.values.password,
        }
        history.replace(AuthScreens.CHANGE_PASSWORD.PATH, params)
      } else {
        dispatch(AuthRedux.actions.login(access_token, refresh_token, change_password))
        showToast('Anda berhasil login ke akun')
      }
    },
    onError: (e: any) => {
      if (e?.code === responseUtils.RESPONSE_CODE.NETWORK_ERROR) {
        setToastMessage(wordingUtils.ErrorMessage.userOffline)
      } else {
        const errCode = e.response.data.response_schema.response_code
        const status = e.response.status
        if (status >= 500 || errCode === responseUtils.RESPONSE_CODE.INTERNAL_SERVER_ERROR) {
          setToastMessage(wordingUtils.ErrorMessage.userOffline)
        } else if (errCode === responseUtils.RESPONSE_CODE.INVALID_CAPTCHA) {
          setToastMessage(wordingUtils.ErrorMessage.invalidCaptcha)
        } else if (errCode === responseUtils.RESPONSE_CODE.INVALID_USER_PASSWORD) {
          setToastMessage(wordingUtils.ErrorMessage.invalidUserPassword)
        } else if (errCode === responseUtils.RESPONSE_CODE.USER_BLOCKED) {
          setToastMessage(wordingUtils.ErrorMessage.inputErrorLimit)
        }else if (errCode === responseUtils.RESPONSE_CODE.PASSWORD_EXCEED_LIMIT) {
          setToastMessage(wordingUtils.ErrorMessage.inputPasswordExpired)
        }else if (errCode === responseUtils.RESPONSE_CODE.DOUBLE_ACCESS) {
          setToastMessage(wordingUtils.ErrorMessage.userAlreadyLoggedIn)
        }
        setTimeout(() => {
          setToastMessage('')
        }, 3000)
        formik.setFieldValue('captcha_value', '')
        getImgCaptcha(uuidv4())
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      if(!isOnline) return handleOffline()
      submitFn.mutate(values)
    },
  })

  const captchaComponent = () => {
    if (imgCaptcha) {
      if (loadingCaptcha) {
        return <GIcon icon='IconLoading' className={`animate-spin w-36`} />
      } else {
        return (
          <img
            src={imgCaptcha}
            alt='captcha'
            id='captcha'
            className={`h-16 w-40 object-contain ${
              loadingCaptcha && Boolean(imgCaptcha) ? 'hidden' : 'visible'
            }`}
          />
        )
      }
    } else {
      return (
        <img
          src={imgCaptcha}
          alt='captcha'
          id='captcha'
          className={`h-10 w-28 object-cover ${
            loadingCaptcha && Boolean(imgCaptcha) ? 'hidden' : 'visible'
          }`}
        />
      )
    }
  }

  useEffect(() => {
    const isFormEmpty = [
      formik.values.nip,
      formik.values.password,
      formik.values.captcha_value,
    ].includes('')
    setFormEmpty(isFormEmpty)
  }, [formik.values])

  return (
    <div data-testid='login-email-page' className='w-full'>
      <div className='mb-2'>
        <div className='mb-1 text-fs-5 font-sanssemibold'>{wordingUtils.Login.Login}</div>
      </div>
      <GAlert
        className={`mb-8 px-1 galert-basic border-danger-500 ${
          toastMessage ? 'visible' : 'hidden'
        } `}
        textClassname='text-danger-500 font-semibold'
        iconClassname='text-danger-500'
      >
        {toastMessage}
      </GAlert>
      <form
        className='w-full'
        onFocus={() => {
          setToastMessage('')
        }}
        onSubmit={formik.handleSubmit}
      >
        <div className='mb-4'>
          <FormLabel required className='mb-2'>
            {wordingUtils.General.NIP}
          </FormLabel>
          <FormText
            {...formik.getFieldProps('nip')}
            inputClassName='border border-neutral-300'
            name='nip'
            type='text'
            placeholder={wordingUtils.Placeholder.inputNip}
          />
        </div>

        <div className='mb-8'>
          <FormLabel required className='mb-2'>
            {wordingUtils.Login.Password}
          </FormLabel>
          <FormPassword
            {...formik.getFieldProps('password')}
            name='password'
            placeholder={wordingUtils.Placeholder.inputPassword}
          />
        </div>

        <div className='mb-2'>
          <FormLabel required className='mb-2'>
            {wordingUtils.Login.verificationCaptcha}
          </FormLabel>
          <div className='flex gap-2 items-center'>
            {captchaComponent()}

            <FormText
              {...formik.getFieldProps('captcha_value')}
              inputClassName='border border-neutral-300 w-48 h-14'
              name='captcha_value'
              type='text'
              placeholder={wordingUtils.Login.inputCaptchaCode}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  formik.handleSubmit()
                }
              }}
            ></FormText>
            <button
              className='border border-signature rounded-md p-2 ml-0'
              onClick={(e) => {
                e.preventDefault()
                getImgCaptcha(uuidv4())
              }}
            >
              <GIcon icon='IconRefresh' className='w-5 h-5 text-signature'></GIcon>
            </button>
          </div>

          <div className='pb-2 mt-2 font-sanssemibold text-end text-signature text-fs-9'>
            <Link to={AuthScreens.FORGOT.PATH}>
              <span className='cursor-pointer'>{wordingUtils.Login.forgotPassword}</span>
            </Link>
          </div>
        </div>

        <GButton
          type='submit'
          size='large'
          className='w-full bg-signature border-signature hover:bg-signature-dark hover:border-signature-dark mb-8 text-neutral-800'
          loading={submitFn.isLoading}
          disabled={isFormEmpty}
        >
          {wordingUtils.Login.Login}
        </GButton>

        <p className=' text-primary text-sm text-center'>{wordingUtils.General.copyright}</p>
      </form>
    </div>
  )
}

export default LoginEmail

import {FC} from 'react'
import InlineSVG from 'react-inlinesvg'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'

export enum GIconCollection {
  IconAdd = '/media/icons/IconAdd.svg',
  IconArrowTop = '/media/icons/IconArrowTop.svg',
  IconBack = '/media/icons/IconBack.svg',
  IconBurger = '/media/icons/IconBurger.svg',
  IconCalendar = '/media/icons/IconCalendar.svg',
  IconCalendarOutline = '/media/icons_v2/swm/outline/calendar.svg',
  IconCheck = '/media/icons/IconCheck.svg',
  IconCheckCircle = '/media/icons/IconCheckCircle.svg',
  IconCheckCircleOutline = '/media/icons_v2/swm/outline/check-circle.svg',
  IconClone = '/media/icons/IconClone.svg',
  IconClose = '/media/icons/IconClose.svg',
  IconCloseRound = '/media/icons/IconCloseRound.svg',
  IconDocumentDownload = '/media/icons/IconDocumentDownload.svg',
  IconDownload = '/media/icons/IconDownload.svg',
  IconDoubleChevronLeft = '/media/icons/IconDoubleChevronLeft.svg',
  IconChevronDown = '/media//icons_v2/swm/outline/chevron-down.svg',
  IconDown = '/media/icons/IconDown.svg',
  IconDelete = '/media/icons_v2/swm/outline/trash-2.svg',
  IconEdit = '/media/icons_v2/swm/outline/edit-1.svg',
  IconEditOutline = '/media/icons_v2/swm/outline/edit-1.svg',
  IconEditSquare = '/media/icons/IconEditSquare.svg',
  IconEmot = '/media/icons/IconEmot.svg',
  IconEyeOn = '/media/icons/IconEyeOn.svg',
  IconEyeOff = '/media/icons/IconEyeOff.svg',
  IconEnvelope = '/media/icons/IconEnvelope.svg',
  IconExclamation = '/media/icons/IconExclamation.svg',
  IconFilter = '/media/icons_v2/swm/outline/filters-3.svg',
  IconFileCSV = '/media/icons/IconFileCSV.svg',
  IconImagePlaceHolder = '/media/icons/IconImagePlaceHolder.svg',
  IconInfo = '/media/icons/IconInfo.svg',
  IconInfoCircle = '/media/icons_v2/swm/outline/info-circle.svg',
  IconLaptop = '/media/icons/IconLaptop.svg',
  IconLoading = '/media/assets/circle-loading.svg',
  IconMoon = '/media/icons/IconMoon.svg',
  IconPaginationRight = '/media/icons/IconPaginationRight.svg',
  IconPaginationLeft = '/media/icons/IconPaginationLeft.svg',
  IconPaperPlane = '/media/icons/IconPaperPlane.svg',
  IconRefresh = '/media/icons_v2/swm/curved/refresh.svg',
  IconSearch = '/media/icons/IconSearch.svg',
  IconSun = '/media/icons/IconSun.svg',
  IconUserFriends = '/media/icons/IconUserFriends.svg',
  IconUp = '/media/icons/IconUp.svg',
  IconUpDown = '/media/icons/IconUpDown.svg',
  IconUpload = '/media/icons/IconUpload.svg',
  ArrowRight = '/media/icons/ArrowRight.svg',
  ArrowLeft = '/media/icons/ArrowLeft.svg',
  IconUploadOutline = '/media/icons_v2/swm/outline/upload.svg',
  IconSignOut = '/media/icons/IconSignOut.svg',
  IconView = '/media/icons/IconView.svg',
  IconWarning = '/media/icons/IconWarning.svg',
  IconEdit1 = '/media/icons/IconEdit1.svg',
  IconChevronUp = '/media/icons/IconChevronUp.svg',
  IconChevronRight = '/media/icons/IconChevronRight.svg',
  IconDangerTriangle = '/media/icons/IconDangerTriangle.svg',
  IconResetDevice = '/media/icons/IconResetDevice.svg',
  IconWarningCircle = '/media/icons/IconWarningCircle.svg',
  IconArrowLeft = '/media/icons/IconArrowLeft.svg',
  IconEyeOpen = '/media/icons_v2/swm/outline/eye-open.svg',
  IconMinimize = '/media/icons_v2/base/minimize-2.svg',
  IconMaximize = '/media/icons_v2/base/maximize-2.svg',
  IconSend = '/media/icons_v2/swm/outline/send-2.svg',
  IconTXT = '/media/icons_v2/swm/outline/document-txt.svg',
  IconBlackBox = '/media/icons/IconBlackBox.svg',
  IconArrowRightBlue = '/media/icons/IconArrowRightBlue.svg',
  IconCalculator = '/media/icons/IconCalculator.svg',
  IconCalculatorRevise = '/media/icons/IconCalculatorRevise.svg',
  IconCalculatorApproved = '/media/icons/IconCalculatorApproved.svg',
  IconQuestionMarkCircle= '/media/icons/IconQuestionMarkCircle.svg',
  IconLoadingSignature= '/media/icons/IconLoadingSignature.svg',
  GreenArrowRight= '/media/icons/GreenArrowRight.svg',
  IconXLSX = '/media/icons/IconXLS.svg',
  IconCheckSignature = '/media/icons/IconCheckSignature.svg',
  IconRevise = '/media/icons/IconRevise.svg',
  IconProfileSetting1 = '/media/icons/IconProfileSetting-1.svg',
  IconProfileSetting2 = '/media/icons/IconProfileSetting-2.svg',
  IconCross = '/media/icons_v2/swm/outline/cross.svg',
  IconAdobePDF = '/media/icons/IconAdobePDF.svg',
  IconPaperPDF = '/media/icons/IconPaperPDF.svg',
  IconMSExcel = '/media/icons/IconMSExcel.svg',
  ArrowRightSignature = '/media/icons/ArrowRightSignature.svg',
  IconRotateRight = '/media/icons_v2/swm/curved/rotate-right.svg',
  IconRotateLeft = '/media/icons_v2/swm/curved/rotate-left.svg',
}

export type GIconProps = keyof typeof GIconCollection

type Props = {
  icon: GIconProps
  className?: string
  onClick?: () => void
}

const GIcon: FC<Props> = ({icon, className, onClick}) => {
  return (
    <InlineSVG src={toAbsoluteUrl(GIconCollection[icon])} className={className} onClick={onClick} />
  )
}

export default GIcon

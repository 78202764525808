import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const AuditTrailScreens = {
  AUDIT_TRAIL: {
    PATH: '/audit-trail',
    TITLE: 'Audit Trail',
    TITLE_ALT: 'Audit Trail',
    PERMISSION: 'reinstatement.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_AUDIT_TRAIL: {
    PATH: '/audit-trail/list',
    HEADER_NAME: 'Audit Trail',
    TITLE: 'Audit Trail',
    TITLE_ALT: 'Audit Trail',
    PERMISSION: 'reinstatement.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default AuditTrailScreens

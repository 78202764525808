/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {useClickOutside} from 'src/app/hooks/click-outside-hook'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import {RootState} from 'src/setup'
import {Card, GButton, GModal} from '../../Libs'
import {KTSVG} from '../../Libs/Icon/KTSVG'
import {DefaultTitle} from './DefaultTitle'
import imageUtils from 'src/app/utils/image-utils'
import {getExpiryUser} from 'src/app/utils/env-utils'
import {logout} from 'src/app/modules/auth/services/Auth.services'
import {User} from 'src/app/modules/permissions/models/User.model'
import GIcon from '../../Libs/Icon/GIcon'
import { useHeaderToast } from 'src/app/context/ToastContext'
import responseUtils from 'src/app/utils/response-utils'

const HeaderWrapper: FC = () => {
  const profilePicSample = imageUtils.ProfilePicSample

  const {minimize, setMinimize} = useMasterLayout()
  const user: User = useSelector<RootState>(({auth}) => auth?.user, shallowEqual) as User
  const authCount: any = useSelector<RootState>(({auth}) => auth?.counter, shallowEqual)
  const access_token: any = useSelector<RootState>(({auth}) => auth.accessToken)

  const dispatch = useDispatch()
  const [clickOutsideRef, isShow, setIsShow] = useClickOutside(false)
  const [logoutModal, setLogoutModal] = useState<boolean>(false)
  const [timer, setTimer] = useState<any>(undefined)

  const authDatetimeActive: any = useSelector<RootState>(
    ({auth}) => auth?.datetimeActive,
    shallowEqual
  )
  const curTime = new Date().getTime()

  const userMenus: any[] = [
    {title: 'Akun Saya', icon: '/media/icons/IconUserProfile.svg', path: '/akun-saya'},
  ]

  const {addPageToasts} = useHeaderToast()
  function handleError(err: any) {
    if (err.code !== responseUtils.RESPONSE_CODE.NETWORK_ERROR) {
      addPageToasts({
        scheme: 'danger',
        text: err.response.data.response_schema.response_message,
      })
    } else {
      addPageToasts({
        scheme: 'danger',
        text: err.message,
      })
    }
  }
  const handleLogout = () => {
    setIsShow(false)
    logout({Authorization: `Bearer ${access_token}`})
    .then(() => dispatch(AuthRedux.actions.logout()))
    .catch(handleError)
  }

  useEffect(() => {
    if (timer) clearTimeout(timer)
    const timeout = setTimeout(() => {
      if (authCount <= 0) dispatch(AuthRedux.actions.logout())
      else {
        const calc = getExpiryUser() * 1000 - (curTime - authDatetimeActive)
        dispatch(AuthRedux.actions.setCounter(Math.round(calc / 1000) - 1))
      }
    }, 1000)
    setTimer(timeout)
  }, [authCount])

  return (
    <>
      <div
        className={clsx(
          'fixed top-0 right-0 z-[99] h-[55px] p-0 shadow-none bg-white flex items-stretch transition-all duration-300',
          'lg:h-[80px]',
          'dark:bg-inverse-primary-light-1 dark:text-white',
          {'left-0 lg:left-[264px]': !minimize},
          {'left-0 lg:left-[72px]': minimize}
        )}
      >
        <div className={clsx('px-8 flex items-stretch justify-between w-full mx-auto')}>
          <div className='flex items-center mr-1 lg:hidden'>
            <div
              data-testid='icon-minimize'
              onClick={() => setMinimize(!minimize)}
              className='inline-flex items-center justify-center w-10 h-10 px-4 border-0 shadow-none outline-none'
            >
              <KTSVG path='/media/icons/IconBurger.svg' className='text-neutral-100' />
            </div>
          </div>

          <div className={clsx('hidden items-stretch justify-between', 'lg:grow lg:flex')}>
            <div className='flex items-center'>
              <DefaultTitle />
            </div>
          </div>

          <div className='flex items-stretch'>
            <div className='flex items-center justify-center'>
              <div className='relative' ref={clickOutsideRef}>
                <div
                  data-testid='icon-profile'
                  onClick={() => setIsShow(!isShow)}
                  className='flex items-center cursor-pointer select-none'
                >
                  <div className='flex items-center justify-center ml-4'>
                    <div
                      className={clsx(
                        'flex items-center justify-center w-10 h-10 font-bold rounded-full text-primary p-2 ',
                        isShow ? 'bg-signature-100' : 'bg-gray-100'
                      )}
                    >
                      <img src={profilePicSample} alt='profile-pic' className='rounded-full' data-testid='profile-pic'/>
                    </div>
                  </div>
                </div>
                {isShow && (
                  <Card.Root className='absolute right-0 top-12 w-[250px] rounded-lg animate-opacity-to-full'>
                    <div className='flex items-center justify-between py-3 px-4'>
                      <div className='flex items-center'>
                        <div className='flex items-center justify-center w-10 h-10 font-bold rounded-full text-primary'>
                          <img src={profilePicSample} alt='profile-pic' className='rounded-full' />
                        </div>
                        <div className='ml-4'>
                          <div className='font-semibold text-fs-9 text-neutral-700' data-testid='user-name'>
                            {user?.name}
                          </div>
                          <div className='text-neutral-500 text-fs-10'>
                            <span className='font-semibold'>NIP:</span> {user?.nip}
                          </div>
                          <div className='text-neutral-400 text-fs-10'>
                            {authCount} seconds remaining
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='border-t border-neutral-30 text-fs-9'>
                      {userMenus.map((obj) => {
                        return (
                          <Link to={obj.path} key={obj.title} onClick={() =>setIsShow(false)}>
                            <div className='py-3 px-4 flex items-center hover:bg-primary-light-2'>
                              <span className='w-8 shrink-0'>
                                <KTSVG path={obj.icon} className='w-6 h-6' />
                              </span>
                              <span className='text-neutral-700'>{obj.title}</span>
                            </div>
                          </Link>
                        )
                      })}
                    </div>
                    <div className='border-t border-neutral-30 text-fs-9'>
                      <div
                        data-testid='btn-logout'
                        onClick={() => {setLogoutModal(true);setIsShow(false)}}
                        className='py-3 px-4 flex items-center cursor-pointer hover:bg-primary-light-2 rounded-lg'
                      >
                        <span className='w-8 shrink-0'>
                          <KTSVG path='/media/icons/IconLogout.svg' className='w-6 h-6' />
                        </span>
                        <span className='text-neutral-700'>Logout</span>
                      </div>
                    </div>
                  </Card.Root>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <GModal 
      show={logoutModal} 
      onHide={() => setLogoutModal(false)} 
      size={'sm'}>
        <div className='flex justify-center flex-col w-full pb-2 pt-4' data-testid='modal-logout'>
          <div className='w-full flex justify-around'>
            <div className='rounded-full bg-warning-50 w-[48px] h-[48px] flex items-center justify-center'>
              <div className='rounded-full bg-warning-100 w-[32px] h-[32px] flex items-center justify-center'>
                <GIcon
                  icon='IconDangerTriangle'
                  className='text-warning-600 w-[24px] h-[24px]'
                ></GIcon>
              </div>
            </div>
          </div>
          <div className='w-full text-center mt-6 text-neutral-600 mb-4'>
            <div className="font-semibold text-[#101828] text-[18px]">Logout</div>
            <div className='text-[#667085] text-[14px] mt-2'>Apakah Anda yakin akan logout dari akun?</div> 
          </div>
          <div className='flex items-center justify-center w-full p-4'>
            <GButton
              data-testid='btn-cancel'
              onClick={() => setLogoutModal(false)}
              type='button'
              variant='OUTLINED'
              className='mr-4 w-[170px] text-neutral-700 border-neutral-300 hover:bg-neutral-300'
            >Batal</GButton>

            <GButton
              data-testid='btn-submit-logout'
              onClick={handleLogout}
              className='w-[170px]'
              type='button'
            >Logout</GButton>
          </div>
        </div>
      </GModal>
    </>
  )
}

export default HeaderWrapper

import axios from 'axios'
import {BaseResponse, Response} from 'src/app/models/api.types'
import {positionEnum} from '../../user-role-management/models/User.model'
import {DashboardDataModel} from '../page/models/Dashboard.models'

export const DASHBOARD_URL = '/api/web/dashboard/summary'

export const getDashboard = (position: positionEnum, params?: any) => {
  return axios.get<BaseResponse<Response<DashboardDataModel>>>(
    `${DASHBOARD_URL}/${position.toLowerCase()}?start-date=${params.start_date}&end-date=${
      params.end_date
    }`
  )
}

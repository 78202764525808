import {FC} from 'react'
import en from 'react-date-object/locales/gregorian_en'
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import {FormText, FormTextProps} from '../Text'
import {FormDateProps} from './Datepicker.types'

const CustomInput: FC<FormTextProps> = ({
  onFocus,
  className,
  value,
  placeholder,
  error,
  touched,
  disabled,
}) => {
  return (
    <FormText
      onFocus={onFocus}
      value={value}
      appendInnerIcon='IconCalendarOutline'
      clickAppendInnerIcon={onFocus as () => void}
      appendInnerClassname='text-neutral-500'
      readOnly
      placeholder={placeholder}
      error={error}
      touched={touched}
      className={className}
      disabled={disabled}
    />
  )
}

export const FormDatepicker: FC<FormDateProps> = ({
  placeholder = 'Enter date',
  error,
  touched,
  format = 'DD/MM/YYYY',
  showTimePicker = false,
  className,
  disabled,
  customInput,
  ...rest
}) => {
  const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  const customRender = customInput ?? (
    <CustomInput
      placeholder={placeholder}
      error={error}
      touched={touched}
      inputClassName={className}
      disabled={disabled}
    />
  )
  return (
    <DatePicker
      containerStyle={{
        width: '100%',
      }}
      highlightToday={false}
      onOpenPickNewDate={false}
      render={customRender}
      weekDays={weekDays}
      format={format}
      plugins={showTimePicker ? [<TimePicker position='bottom' hideSeconds key={'time'} />] : []}
      showOtherDays
      className='custom-calendar'
      monthYearSeparator=' '
      locale={en}
      disabled={disabled}
      {...rest}
    />
  )
}

import GIcon from "../../Libs/Icon/GIcon"

export const panelKSM = (currentStep : number) => {
    return currentStep === 0 ? (
        <div className={`h-8 w-8 rounded-lg bg-signature justify-center`}>
          <h5
            className={`text-center p-1 font-bold ${
              currentStep === 0 ? 'text-neutral-100' : 'text-neutral-70'
            } `}
          >
            {1}
          </h5>
        </div>
      ) : (
        <div className='flex flex-col h-8 w-8 rounded-lg bg-signature-2-300 border-2 border-signature justify-center'>
          <GIcon
            icon='IconCheck'
            className='text-signature text-sm h-4 w-4 align-middle text-center self-center'
          />
        </div>
      )
}

export const conditionChecker = (condition: boolean, value1: any, value2: any) => {
    return condition ? value1 : value2
}
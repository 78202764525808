import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {FormLabel, FormPassword, GButton} from 'src/app/components/Libs'
import {useWebTitle} from 'src/app/hooks/title-hook'
import * as Yup from 'yup'
import {resetPasswordUser} from '../../../permissions/services/Permission.services'
import wordingUtils from 'src/app/utils/wording-utils'
import imageUtils from 'src/app/utils/image-utils'
import {useDispatch} from 'react-redux'
import AuthRedux from '../../redux/AuthRedux'
import {logout} from '../../services/Auth.services'

const ChangePassword: FC = () => {
  type errPanelType = 'NOT_MATCH' | 'NOT_QUALIFIED' | 'EQUAL_WITH_OLD' | ''
  useWebTitle('Ubah Password')
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const [errPass, setErrPass] = useState<errPanelType>('')

  const panelNotEqual = () => {
    return (
      <div className='border border-solid border-danger-500 bg-danger-50 rounded-lg px-5 py-2'>
        <div className='flex flex-row'>
          <div className='flex flex-col justify-center'>
            <img src={imageUtils.CircleDanger} alt='forgot-password-success' className='w-6 h-6' />
          </div>
          <div className='ml-4'>
            <div className='font-semibold text-sm text-danger-600'>
              {errPass === 'NOT_MATCH' && wordingUtils.CreatePassword.confirmPassNotEqual}
              {errPass === 'EQUAL_WITH_OLD' && wordingUtils.CreatePassword.sameWithOldPass}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const errPanel = (errType: errPanelType) => {
    if (errType === 'NOT_QUALIFIED') {
      return panelNotMatch()
    } else if (['NOT_MATCH', 'EQUAL_WITH_OLD'].includes(errType)) {
      return panelNotEqual()
    }
  }

  const {access_token, old_password}: any = location.state

  const resetSchema = Yup.object().shape({
    password: Yup.string().required('Masukkan password baru'),
    new_password: Yup.string()
      .min(8, 'Password minimal 8 karakter')
      .required('Masukkan konfirmasi password baru'),
  })

  const initialValues = {
    password: '',
    new_password: '',
  }

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      const val = {
        old_password: old_password,
        new_password: payload.new_password,
      }

      return resetPasswordUser(val, {Authorization: `Bearer ${access_token}`}).then(() =>
        logout({Authorization: `Bearer ${access_token}`})
      )
    },
    onSuccess: () => {
      formik.setSubmitting(false)
      dispatch(AuthRedux.actions.logout())
      history.replace('/')
    },
    onError: (e: any) => {
      const code = e?.response?.data?.response_schema?.response_code ?? ''
      if (code === 'MANDIRI-LEADS-0009') {
        setErrPass('EQUAL_WITH_OLD')
        setTimeout(() => setErrPass(''), 3000)
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: resetSchema,
    onSubmit: (values) => {
      if (!passRegex.test(values.password)) {
        setErrPass('NOT_QUALIFIED')
        setTimeout(() => {
          setErrPass('')
        }, 3000)
      } else if (values.password !== values.new_password) {
        setErrPass('NOT_MATCH')
        setTimeout(() => {
          setErrPass('')
        }, 3000)
      } else if (values.new_password === old_password) {
        setErrPass('EQUAL_WITH_OLD')
        setTimeout(() => {
          setErrPass('')
        }, 3000)
      } else {
        submitFn.mutate(values)
      }
    },
  })

  return (
    <div data-testid='create-new-password-page' className='w-full'>
      <div className='mb-6 text-center'>
        <div className='font-semibold text-fs-5 text-left'>{wordingUtils.CreatePassword.title}</div>
      </div>
      <div className='mb-6'>{errPanel(errPass)}</div>

      <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
        <div className='mb-8'>
          <FormLabel required className='mb-2'>
            {wordingUtils.CreatePassword.newPassword}
          </FormLabel>
          <FormPassword
            {...formik.getFieldProps('password')}
            name='password'
            required
            placeholder={wordingUtils.Placeholder.inputNewPassword}
            maxLength={50}
            error={formik.errors.password}
            touched={formik.touched.password}
          />
        </div>

        <div className='mb-8'>
          <FormLabel required className='mb-2'>
            {wordingUtils.CreatePassword.confirmNewPassword}
          </FormLabel>
          <FormPassword
            {...formik.getFieldProps('new_password')}
            name='new_password'
            required
            placeholder={wordingUtils.Placeholder.reInputNewPassword}
            maxLength={50}
            error={formik.errors.new_password}
            touched={formik.touched.new_password}
          />
        </div>

        <GButton
          type='submit'
          size='large'
          className='w-full'
          loading={submitFn.isLoading}
          // disabled={!formik.isValid}
        >
          {wordingUtils.Button.savePassword}
        </GButton>
      </form>
    </div>
  )
}
export const passRegex = /^(?=.*[A-Z])(?=.*[!@#$&*%^()_-])(?=.*[a-z])(?=.*\d).{8,}$/
export const panelNotMatch = () => {
  return (
    <div className='border border-solid border-danger-500 bg-danger-50 rounded-lg px-5 py-2'>
      <div className='flex flex-row'>
        <div className='flex flex-col justify-center'>
          <img src={imageUtils.CircleDanger} alt='forgot-password-success' className='w-6 h-6' />
        </div>
        <div className='ml-4'>
          <div className='font-semibold mb-2  text-sm text-danger-600'>
            {wordingUtils.CreatePassword.passRequire}
          </div>
          <ul>
            <li className='font-semibold text-sm text-danger-600'>
              {wordingUtils.CreatePassword.passRequire1}
            </li>
            <li className='font-semibold text-sm text-danger-600'>
              {wordingUtils.CreatePassword.passRequire2}
            </li>
            <li className='font-semibold text-sm text-danger-600'>
              {wordingUtils.CreatePassword.passRequire3}
            </li>
            <li className='font-semibold text-sm text-danger-600'>
              {wordingUtils.CreatePassword.passRequire4}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword

import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const UserRoleManagementScreens = {
  UserManagement: {
    PATH: '/user-management',
    TITLE: 'User Management',
    TITLE_ALT: 'User Management',
    PERMISSION: 'USER_MANAGEMENT.read',
  },
  ViewUserManagement: {
    PATH: '/user-management/view/:id',
    TITLE: 'User & Role Management',
    TITLE_ALT: 'Detail Data User',
    PERMISSION: 'USER_MANAGEMENT.read',
  },
  EditUserManagement: {
    PATH: '/user-management/edit/:id',
    TITLE: 'User & Role Management',
    TITLE_ALT: 'Edit Data User',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'USER_MANAGEMENT.insert_update',
  },
  AddUserManagement: {
    PATH: '/user-management/add',
    TITLE: 'User & Role Management',
    TITLE_ALT: 'Tambah Data User',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'USER_MANAGEMENT.insert_update',
  },
  ResetPassword: {
    PATH: '/user-management/reset-password/:id',
    TITLE: 'User & Role Management',
    TITLE_ALT: 'Ganti Password',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'USER_MANAGEMENT.insert_update',
  },
  RoleManagement: {
    PATH: `/role-management`,
    TITLE: 'User & Role Management',
    TITLE_ALT: 'Role Management',
    PERMISSION: 'ROLE_MANAGEMENT.read',
    MENU_RESOURCE: 'ROLE_MANAGEMENT',
  },
  AddRole: {
    PATH: '/role-management/add',
    TITLE: 'Add Role',
    TITLE_ALT: 'Role Management',
    PERMISSION: 'ROLE_MANAGEMENT.insert_update',
    MENU_RESOURCE: 'ROLE_MANAGEMENT',
  },
  RoleBreadCrumbs: [
    {
      isActive: false,
      path: '/role-management',
      title: 'Role Management',
    },
    {isActive: false, path: '', title: '', isSeparator: true},
  ],
  ViewApprovedRole: {
    PATH: '/role-management/view/:roleCode',
    TITLE: 'Detail Role',
    TITLE_ALT: 'Role Management',
    PERMISSION: 'ROLE_MANAGEMENT.read',
    MENU_RESOURCE: 'ROLE_MANAGEMENT',
  },
  EditApprovedRole: {
    PATH: '/role-management/edit/:roleCode',
    TITLE: 'Edit Role',
    TITLE_ALT: 'Role Management',
    PERMISSION: 'ROLE_MANAGEMENT.insert_update',
  },
  ViewUnapprovedRole: {
    PATH: '/role-management/history/:roleId',
    TITLE: 'Detail Role',
    TITLE_ALT: 'Role Management',
    PERMISSION: 'ROLE_MANAGEMENT.read',
  },
  ReviseUnapprovedRole: {
    PATH: '/role-management/history/revise/:roleId',
    TITLE: 'View Role',
    TITLE_ALT: 'Role Management',
    PERMISSION: 'ROLE_MANAGEMENT.insert_update',
  },
}

export default UserRoleManagementScreens

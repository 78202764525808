import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {env} from 'src/app/utils/env-utils'

namespace LocalizationRedux {
  const LOCALIZATION_KEY = () => {
    return env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
  }

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export interface ILocalizationState {
    translation?: ITranslationState
  }

  export type ITranslationState = 'en' | 'de' | 'id' | 'ko' | 'fr' | 'pt' | 'ja'

  export const actionTypes = {
    SetLanguage: '[SetLanguage] Action',
  }

  export const initialLocalizationState: ILocalizationState = {
    translation: 'en',
  }

  export const reducer = () =>
    persistReducer<ILocalizationState, ActionWithPayload<ILocalizationState>>(
      {
        storage,
        key: LOCALIZATION_KEY(),
        whitelist: ['translation'],
      },
      (state: ILocalizationState = initialLocalizationState, action = {type: ''}) => {
        if (action.type === actionTypes.SetLanguage) {
          {
            const translation = action.payload?.translation
            return {...state, translation}
          }
        } else {
          return state
        }
      }
    )

  export const actions = {
    setLanguage: (translation: ITranslationState) => ({
      type: actionTypes.SetLanguage,
      payload: {translation},
    }),
  }
}

export default LocalizationRedux

const ApprovalScreens = {
  Approval: {
    PATH: '/approval',
    TITLE: 'Approval',
    TITLE_ALT: 'Approval',
    PERMISSION: 'APPROVAL_IDEB_SLIK.read',
  },
  ApprovalIdebSlik: {
    PATH: '/approval/ideb-slik',
    TITLE: 'Approval iDEB SLIK',
    TITLE_ALT: 'Approval iDEB SLIK',
    PERMISSION: 'APPROVAL_IDEB_SLIK.read',
  },

  ApprovalIdebSlikDetail: {
    PATH: '/approval/ideb-slik/detail',
    TITLE: 'Approval iDEB SLIK Detail',
    TITLE_ALT: 'Approval iDEB SLIK Detail',
    PERMISSION: 'APPROVAL_IDEB_SLIK.read',
  },
  ApprovalPindahKelola: {
    PATH: '/approval/pindah-kelola',
    TITLE: 'Approval Pindah Kelola',
    TITLE_ALT: 'Approval Pindah Kelola',
    PERMISSION: 'APPROVAL_PINDAH_KELOLA.read',
  },
  ViewApprovalPindahKelola: {
    PATH: '/approval/pindah-kelola/view/:id',
    TITLE: 'Approval',
    TITLE_ALT: 'Detail Data Nasabah Pindah Kelola',
    PERMISSION: 'APPROVAL_PINDAH_KELOLA.read',
  },
  ApprovalProspecting: {
    PATH: '/approval/prospecting',
    TITLE: 'Approval',
    TITLE_ALT: 'Approval Prospecting',
    PERMISSION: 'APPROVAL_PROSPECTING.read',
  },
  ViewApprovalProspecting: {
    PATH: '/approval/prospecting/view/:id',
    TITLE: 'Approval',
    TITLE_ALT: 'Detail Data Prospecting Nasabah',
    PERMISSION: 'APPROVAL_PROSPECTING.read',
  },
  Blackbox: {
    PATH: '/blackbox',
    TITLE: 'Blackbox',
    TITLE_ALT: 'Blackbox',
    PERMISSION: 'BLACKBOX.read',
  },
  ApprovalClosing: {
    PATH: '/approval/closing',
    TITLE: 'Approval',
    TITLE_ALT: 'Approval Closing',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },
  KSMCalculator: {
    PATH: '/approval/closing/calculator/ksm/:id',
    TITLE: 'Approval',
    TITLE_ALT: 'Cek Simulasi Kalkulator KSM',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },
  EMANCalculator: {
    PATH: '/approval/closing/calculator/eman/:step',
    TITLE: 'Approval Closing',
    TITLE_ALT: 'Cek Kalkulator Simulasi Estimasi/Manfaat',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },
  PINPENCalculator: {
    PATH: '/approval/sipinpen/detail',
    TITLE: 'Approval Closing',
    TITLE_ALT: 'Cek Simulasi Kalkulator PINPEN',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },
  ApprovalClosingDetail: {
    PATH: '/approval/closing/detail/:id',
    TITLE: 'Approval',
    TITLE_ALT: 'Detail Data Nasabah',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },
  KalkulatorSiPinPenPage: {
    PATH: '/approval/sipinpen/detail/:id',
    TITLE: 'Approval',
    TITLE_ALT: 'Cek Kalkulator Simulasi Pinjaman Pensiun',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },
  KalkulatorSiPinPenRejectPage: {
    PATH: '/approval/sipinpen/detail/:id/reject',
    TITLE: 'Approval',
    TITLE_ALT: 'Cek Kalkulator Simulasi Pinjaman Pensiun',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },
  KalkulatorSiPinPenStep1: {
    PATH: '/approval/sipinpen/detail/:id/step-1',
    TITLE: 'Approval',
    TITLE_ALT: 'Detail Data',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },

  KalkulatorSiPInPenStep2: {
    PATH: '/approval/sipinpen/detail/:id/step-2',
    TITLE: 'Approval',
    TITLE_ALT: 'Detail Data',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },
  KalkulatorSiPinPenStep3: {
    PATH: '/approval/sipinpen/detail/:id/step-3',
    TITLE: 'Approval',
    TITLE_ALT: 'Detail Data',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },
  KalkulatorSiPinPenStep4: {
    PATH: '/approval/sipinpen/detail/:id/step-4',
    TITLE: 'Approval',
    TITLE_ALT: 'Detail Data',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },
  ApprovalClosingDetailSimplified: {
    PATH: '/approval/closing/detail-simplified/:id',
    TITLE: 'Approval',
    TITLE_ALT: 'Detail Data Nasabah',
    PERMISSION: 'APPROVAL_CLOSING.read',
  },
}

export default ApprovalScreens

import {FC, useEffect, useState} from 'react'
import {ConnectedProps, connect, shallowEqual, useSelector} from 'react-redux'
import {LayoutSplashScreen} from 'src/app/context/SplashScreenContext'
import {RootState} from 'src/setup'
import AuthRedux from './AuthRedux'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, AuthRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  children?: React.ReactNode
}

const AuthInit: FC<Props> = (props) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const user = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  useEffect(() => {
    setShowSplashScreen(false)
  }, [user])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)

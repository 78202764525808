import {cva} from 'class-variance-authority'
export type IconButtonThemeEnum =
  | 'primary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'neutral'
  | null
  | undefined
export const iconButton = cva(
  ['outline-0', 'border-0', 'p-0', 'w-8', 'h-8', 'border-none', 'rounded'],
  {
    variants: {
      colors: {
        primary: ['bg-primary-light-2', 'text-primary'],
        success: ['bg-success-light-2', 'text-success'],
        danger: ['bg-danger-light-2', 'text-danger'],
        warning: ['bg-warning-light-2', 'text-warning'],
        info: ['bg-info-light-50', 'text-info'],
        neutral: ['bg-neutral-20', 'text-neutral-60'],
      },
    },
    compoundVariants: [
      {
        colors: 'primary',
      },
    ],
    defaultVariants: {
      colors: 'primary',
    },
  }
)

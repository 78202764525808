import clsx from 'clsx'
import {useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {ModulePermissionQuery} from 'src/app/modules/permissions/models/Role.model'
import {User} from 'src/app/modules/permissions/models/User.model'
import {RootState} from 'src/setup'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import PipelineDistributionScreens from 'src/app/modules/pipeline-distribution/Screens'
import SalesFrontingScreens from 'src/app/modules/sales-fronting/Screens'
import ApprovalScreens from 'src/app/modules/approval/Screens'
import MonitoringScreens from 'src/app/modules/monitoring/Screens'
import ParameterMasterDataScreens from 'src/app/modules/parameter-master-data/Screens'
import DashboardScreens from 'src/app/modules/dashboard/Screens'
import UserRoleManagementScreens from 'src/app/modules/user-role-management/Screens'
import BlackBoxScreens from 'src/app/modules/blackbox/Screens'
import {usePermissions} from '../../../hooks/permissions-hook'
import MailboxScreens from 'src/app/modules/mailbox/Screens'
import ReportScreens from 'src/app/modules/report/Screens'

interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
  disabled?: boolean
}

const useMenus = (user?: User): Menu[] => {
  const position = String(user?.position).toLowerCase() ?? ''
  return useMemo(
    () =>
      [
        {
          // title: 'Building Blocks',
          children: [
            {
              to: DashboardScreens.Dashboard.PATH,
              icon: '/media/icons/IconDashboardReport.svg',
              title: DashboardScreens.Dashboard.TITLE,
              module_permissions: DashboardScreens.Dashboard.PERMISSION,
            },
            {
              to: ReportScreens.REPORT.PATH,
              icon: '/media/icons/IconExport.svg',
              title: ReportScreens.REPORT.TITLE,
              module_permissions: ReportScreens.REPORT.PERMISSION,
              children: [
                {
                  to: ReportScreens.AKTIFITAS_PENGGUNA_LMS.PATH.replace(':pos', position),
                  title: ReportScreens.AKTIFITAS_PENGGUNA_LMS.TITLE_ALT,
                  hasBullet: true,
                  module_permissions: ReportScreens.AKTIFITAS_PENGGUNA_LMS.PERMISSION,
                },
                {
                  to: ReportScreens.SALES_FEEDBACK.PATH.replace(':pos', position),
                  title: ReportScreens.SALES_FEEDBACK.TITLE_ALT,
                  hasBullet: true,
                  module_permissions: ReportScreens.SALES_FEEDBACK.PERMISSION,
                },
                {
                  to: ReportScreens.DATA_BOOKING.PATH.replace(':pos', position),
                  title: ReportScreens.DATA_BOOKING.TITLE_ALT,
                  hasBullet: true,
                  module_permissions: ReportScreens.DATA_BOOKING.PERMISSION,
                },
              ],
            },
            {
              to: PipelineDistributionScreens.PipelineDistribution.PATH,
              icon: '/media/icons/IconPipelineDistribution.svg',
              title: PipelineDistributionScreens.PipelineDistribution.TITLE,
              module_permissions: PipelineDistributionScreens.PipelineDistribution.PERMISSION,
              children: [
                {
                  to: PipelineDistributionScreens.NewPipelineDistribution.PATH,
                  title: PipelineDistributionScreens.NewPipelineDistribution.TITLE_ALT,
                  hasBullet: true,
                  module_permissions:
                    PipelineDistributionScreens.NewPipelineDistribution.PERMISSION,
                },
                {
                  to: PipelineDistributionScreens.ExistingPipelineDistribution.PATH,
                  title: PipelineDistributionScreens.ExistingPipelineDistribution.TITLE_ALT,
                  hasBullet: true,
                  module_permissions:
                    PipelineDistributionScreens.ExistingPipelineDistribution.PERMISSION,
                },
              ],
            },
            {
              to: SalesFrontingScreens.SalesFronting.PATH,
              icon: '/media/icons/IconSalesFronting.svg',
              title: SalesFrontingScreens.SalesFronting.TITLE,
              module_permissions: SalesFrontingScreens.SalesFronting.PERMISSION,
              children: [
                {
                  to: SalesFrontingScreens.SalesFronting.PATH,
                  hasBullet: true,
                  title: SalesFrontingScreens.SalesFronting.TITLE_BREADCRUMB,
                  module_permissions: SalesFrontingScreens.SalesFronting.PERMISSION,
                },
              ],
            },
            {
              to: ApprovalScreens.Approval.PATH,
              icon: '/media/icons/IconApproval.svg',
              title: ApprovalScreens.Approval.TITLE,
              module_permissions: '',
              children: [
                {
                  to: ApprovalScreens.ApprovalIdebSlik.PATH,
                  title: 'Approval iDEB SLIK',
                  hasBullet: true,
                  module_permissions: ApprovalScreens.ApprovalIdebSlik.PERMISSION,
                },
                {
                  to: ApprovalScreens.ApprovalPindahKelola.PATH,
                  title: 'Approval Pindah Kelola',
                  hasBullet: true,
                  module_permissions: ApprovalScreens.ApprovalPindahKelola.PERMISSION,
                },
                {
                  to: ApprovalScreens.ApprovalProspecting.PATH,
                  title: 'Approval Prospecting',
                  hasBullet: true,
                  module_permissions: ApprovalScreens.ApprovalProspecting.PERMISSION,
                },
                {
                  to: ApprovalScreens.ApprovalClosing.PATH,
                  title: 'Approval Closing',
                  hasBullet: true,
                  module_permissions: ApprovalScreens.ApprovalClosing.PERMISSION,
                },
              ],
            },
            {
              to: MonitoringScreens.Monitoring.PATH,
              icon: '/media/icons/IconMonitoring.svg',
              title: MonitoringScreens.Monitoring.TITLE,
              module_permissions: MonitoringScreens.Monitoring.PERMISSION,
              children: [
                {
                  to: MonitoringScreens.SALES_ACTIVITY.PATH.replace(':pos', position),
                  title: MonitoringScreens.SALES_ACTIVITY.TITLE_ALT,
                  hasBullet: true,
                  module_permissions: MonitoringScreens.Monitoring.PERMISSION,
                },
                {
                  to: MonitoringScreens.GEOTRACKING_GEOTAGGING.PATH.replace(':pos', position),
                  title: MonitoringScreens.GEOTRACKING_GEOTAGGING.TITLE_ALT,
                  hasBullet: true,
                  module_permissions: MonitoringScreens.GEOTRACKING_GEOTAGGING.PERMISSION,
                },
                {
                  to: MonitoringScreens.AuditTrail.PATH,
                  title: MonitoringScreens.AuditTrail.TITLE,
                  hasBullet: true,
                  module_permissions: MonitoringScreens.AuditTrail.PERMISSION,
                },
              ],
            },
            {
              to: ParameterMasterDataScreens.ParameterMasterData.PATH,
              icon: '/media/icons/IconParamMasterData.svg',
              title: 'Parameter & Master Data',
              module_permissions: ParameterMasterDataScreens.ParameterMasterData.PERMISSION,
              children: [
                {
                  to: ParameterMasterDataScreens.Parameter.PATH,
                  title: 'Parameter',
                  hasBullet: true,
                  module_permissions: ParameterMasterDataScreens.ProductConfig.PERMISSION,
                },
                {
                  to: ParameterMasterDataScreens.MasterData.PATH,
                  title: 'Master Data',
                  hasBullet: true,
                  module_permissions: ParameterMasterDataScreens.MasterData.PERMISSION,
                },
                {
                  to: MailboxScreens.MailboxParameter.PATH,
                  title: MailboxScreens.MailboxParameter.TITLE_ALT,
                  hasBullet: true,
                  module_permissions: MailboxScreens.MailboxParameter.PERMISSION,
                },
              ],
            },
            {
              to: UserRoleManagementScreens.UserManagement.PATH,
              icon: '/media/icons/IconUserRoleManagement.svg',
              title: 'User & Role Management',
              module_permissions: UserRoleManagementScreens.UserManagement.PERMISSION,
              children: [
                {
                  to: UserRoleManagementScreens.UserManagement.PATH,
                  title: 'User Management',
                  hasBullet: true,
                  module_permissions: UserRoleManagementScreens.UserManagement.PERMISSION,
                },
                {
                  to: UserRoleManagementScreens.RoleManagement.PATH,
                  title: 'Role Management',
                  hasBullet: true,
                  module_permissions: UserRoleManagementScreens.RoleManagement.PERMISSION,
                },
              ],
            },
            {
              to: BlackBoxScreens.BlackBox.PATH,
              title: 'Black Box',
              icon: '/media/icons/IconBlackBox.svg',
              module_permissions: BlackBoxScreens.BlackBox.PERMISSION,
            },
            {
              to: '/calculator',
              title: 'Kalkulator',
              icon: '/media/icons/IconCalculator.svg',
              module_permissions: 'CALCULATOR.read',
            },
            {
              to: MailboxScreens.Mailbox.PATH,
              title: MailboxScreens.Mailbox.TITLE,
              icon: '/media/icons/IconMail.svg',
              module_permissions: MailboxScreens.Mailbox.PERMISSION,
            },
          ],
        },
      ] as Menu[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )
}

const GeneratedMenu: React.FC<{menu: Menu}> = ({menu}) => {
  const {minimize} = useMasterLayout()

  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='block p-0'>
          <div className='px-6 pt-8 pb-2'>
            <span
              className={clsx('tracking-widest text-white uppercase text-fs-10', {
                hidden: minimize,
              })}
            >
              {menu.title}
            </span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
        disabled={menu.disabled}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
      hasBullet={menu.hasBullet}
      disabled={menu.disabled}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user: User = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as User
  const menus = useMenus(user)
  const {hasAccess} = usePermissions()

  const generated = useMemo(
    () =>
      filterMenus(menus, (menu) => hasAccess(menu?.module_permissions) && !Boolean(menu.hidden)),
    [menus, hasAccess]
  )

  // const generated = useMemo(() => filterMenus(menus, (menu) => !Boolean(menu.hidden)), [menus])
  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData

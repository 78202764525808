import {FC, useCallback} from 'react'
import {Link} from 'react-router-dom'
import {cn} from 'src/app/utils/cn-utils'
import GIcon from '../Icon/GIcon'
import {filledButton, ghostButton, outlinedButton, unstyledButton} from './Button.theme'
import {
  BaseButtonProps,
  FilledButtonProps,
  GeneratedButtonProps,
  GhostButtonProps,
  IconSizes,
  OutlinedButtonProps,
} from './Button.types'

const BaseButton: FC<BaseButtonProps> = ({
  loading,
  startIcon,
  endIcon,
  children,
  size = 'medium',
}) => (
  <div className='flex items-center justify-center'>
    {loading && <GIcon icon='IconLoading' className={`${IconSizes[size]} animate-spin`} />}
    {!loading && (
      <>
        {startIcon && <GIcon icon={startIcon} className={`${IconSizes[size ?? 'medium']} mr-3`} />}
        {children}
        {endIcon && <GIcon icon={endIcon} className={`${IconSizes[size ?? 'medium']} ml-3`} />}
      </>
    )}
  </div>
)

export const FilledButton: FC<FilledButtonProps> = (props) => {
  const {colors, size, className, children, loading, startIcon, endIcon, ...rest} = props
  return (
    <button className={cn(filledButton({colors, size, className}))} {...rest}>
      <BaseButton loading={loading} startIcon={startIcon} endIcon={endIcon} size={size ?? 'medium'}>
        {children}
      </BaseButton>
    </button>
  )
}

export const OutlinedButton: FC<OutlinedButtonProps> = (props) => {
  const {colors, size, className, children, loading, startIcon, endIcon, ...rest} = props

  return (
    <button className={cn(outlinedButton({colors, size, className}))} {...rest}>
      <BaseButton loading={loading} startIcon={startIcon} endIcon={endIcon} size={size ?? 'medium'}>
        {children}
      </BaseButton>
    </button>
  )
}

export const GhostButton: FC<GhostButtonProps> = (props) => {
  const {colors, size, className, children, loading, startIcon, endIcon, ...rest} = props
  return (
    <button className={cn(ghostButton({colors, size, className}))} {...rest}>
      <BaseButton loading={loading} startIcon={startIcon} endIcon={endIcon} size={size ?? 'medium'}>
        {children}
      </BaseButton>
    </button>
  )
}

export const UnstyledButton: FC<GhostButtonProps> = (props) => {
  const {colors, size, className, children, loading, startIcon, endIcon, ...rest} = props
  return (
    <button className={cn(unstyledButton({size, className}))} {...rest}>
      <BaseButton loading={loading} startIcon={startIcon} endIcon={endIcon} size={size ?? 'medium'}>
        {children}
      </BaseButton>
    </button>
  )
}

export const GButton: FC<GeneratedButtonProps> = ({
  href,
  children,
  variant = 'FILLED',
  ...rest
}) => {
  const renderButton = useCallback(() => {
    switch (variant) {
      case 'FILLED':
        return <FilledButton {...rest}>{children}</FilledButton>
      case 'OUTLINED':
        return <OutlinedButton {...rest}>{children}</OutlinedButton>
      case 'GHOST':
        return <GhostButton {...rest}>{children}</GhostButton>
      case 'UNSTYLED':
        return <UnstyledButton {...rest}>{children}</UnstyledButton>
      default:
        return <FilledButton {...rest}>{children}</FilledButton>
    }
  }, [children, rest, variant])
  if (href) return <Link to={href}>{renderButton()}</Link>
  return <>{renderButton()}</>
}

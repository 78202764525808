import {Action} from '@reduxjs/toolkit'

namespace AuditTrailRedux {
  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export interface IAuditTrailState {
    cmsMenu?: string
    cmsUserAction?: string
  }

  export const actionTypes = {
    SubmitAudit: '[Submit Audit] Action',
    ClearAudit: '[Clear Audit] Action',
  }

  export const initialAuditState: IAuditTrailState = {
    cmsMenu: undefined,
    cmsUserAction: undefined,
  }

  export const reducer = (
    state: IAuditTrailState = initialAuditState,
    action: ActionWithPayload<IAuditTrailState> = {type: ''}
  ) => {
    switch (action.type) {
      case actionTypes.SubmitAudit: {
        const cmsMenu = action.payload?.cmsMenu
        const cmsUserAction = action.payload?.cmsUserAction

        return {...state, cmsMenu, cmsUserAction}
      }

      case actionTypes.ClearAudit: {
        return initialAuditState
      }

      default:
        return state
    }
  }

  export const actions = {
    submitAudit: ({cmsMenu, cmsUserAction}: IAuditTrailState) => ({
      type: actionTypes.SubmitAudit,
      payload: {cmsMenu, cmsUserAction},
    }),
    clearAudit: () => ({type: actionTypes.ClearAudit}),
  }
}

export default AuditTrailRedux

import {FC, useEffect, useState} from 'react'
import Select, {ClassNamesConfig, SelectComponentsConfig} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {CustomReactSelect} from '../../Custom/CustomReactSelect'
import {FormError} from '../FormError'
import {FormSelectProps, SelectOption} from './Select.types'

export const FormSelect: FC<FormSelectProps> = ({
  options,
  placeholder,
  error,
  touched,
  defaultValue,
  className,
  changedValue,
  disabled,
  selectedValue,
  asyncSelect,
  loadOptions,
  selectComponent,
}) => {
  const [val, setVal] = useState<SelectOption | SelectOption[] | ''>('')

  const onChange = (v: SelectOption) => {
    if (changedValue) {
      changedValue(v)
      setVal(v)
    }
  }

  useEffect(() => {
    if (!asyncSelect) {
      if (selectedValue === '' || selectedValue === undefined) {
        setVal('')
      } else {
        const payload = options?.find((pay) => pay.value === selectedValue)
        setVal({value: selectedValue, label: payload?.label})
      }
    }
  }, [selectedValue, options, asyncSelect])

  useEffect(() => {
    if (asyncSelect && defaultValue) {
      setVal({value: defaultValue?.value, label: defaultValue?.label})
    }
  }, [defaultValue, asyncSelect])

  const hardClassName: ClassNamesConfig<any, false, any> = {
    placeholder: () => 'text-neutral-60 font-medium',
    control: (state) =>
      `text-fs-9 rounded-lg font-medium text-neutral-100 ${
        touched && error ? 'border-danger' : 'border-none'
      } ${disabled && 'bg-neutral-40'} ${
        state.selectProps.menuIsOpen && 'ring-2 ring-[#F2B944] shadow shadow-[#F2B944]'
      }`,
    valueContainer: (state) => `min-h-0 px-4 ${state.isMulti ? 'py-0 h-11' : 'py-3'}`,
    input: () => 'm-0 p-0',
    dropdownIndicator: (state) =>
      `transition-transform duration-150 ${
        state.selectProps.menuIsOpen ? 'rotate-180' : 'rotate-0'
      }`,
    indicatorsContainer: () => 'max-h-[44px]',
    option: (state) =>
      `text-fs-9 rounded px-2 py-3 cursor-pointer ${
        state.isFocused ? 'text-primary' : 'text-neutral-80'
      }`,
    menuList: () => 'p-3 border-none max-h-[320px]',
    menu: () => 'shadow-none drop-shadow-[0_0_50px_rgba(33,37,41,0.13)]',
  }

  return (
    <div data-testid='formselect-test' className={className}>
      {asyncSelect ? (
        <AsyncPaginate
          data-testid='formselect-test-value'
          defaultOptions
          placeholder={placeholder}
          onChange={onChange}
          loadOptions={loadOptions}
          debounceTimeout={500}
          components={
            selectComponent ?? (CustomReactSelect as SelectComponentsConfig<any, false, any>)
          }
          value={val}
          defaultValue={defaultValue}
          isDisabled={disabled}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#F5F7F9' : '',
            }),
          }}
          classNames={hardClassName}
          isSearchable
        />
      ) : (
        <Select
          data-testid='formselect-test-value'
          components={
            selectComponent ?? (CustomReactSelect as SelectComponentsConfig<any, false, any>)
          }
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={val}
          isDisabled={disabled}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#F5F7F9' : '#FFFFFF'
            }),
          }}
          classNames={hardClassName}
        />
      )}

      {touched && error && <FormError>{error}</FormError>}
    </div>
  )
}

import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {FormLabel, FormText, GAlert, GButton, GModal} from 'src/app/components/Libs'
import * as Yup from 'yup'
import {forgetPassword} from '../../../permissions/services/Permission.services'
import wordingUtils from 'src/app/utils/wording-utils'
import imageUtils from 'src/app/utils/image-utils'
import {useHistory} from 'react-router-dom'

const ForgotPassword: FC = () => {
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)

  const loginSchema = Yup.object().shape({
    nip: Yup.string().required(wordingUtils.Placeholder.inputNip),
  })

  const initialValues = {
    nip: '',
  }

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      return forgetPassword(payload.nip)
    },
    onSuccess: (e) => {
      setShowModal(true)
    },
    onError: (e: any) => {
      const status = e.response.status
      formik.setErrors({
        nip: 'NIP tidak ditemukan',
      })

      if (status >= 500) {
        formik.setErrors({nip: wordingUtils.ErrorMessage.userOffline})
      } else {
        formik.setErrors({nip: wordingUtils.ErrorMessage.nipNotFound})
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => submitFn.mutate(values),
  })

  const onModalClose = () => {
    setShowModal(false)
    localStorage.clear()
    history.replace('/')
  }

  return (
    <div data-testid='forgot-password-page' className='w-full'>
      <GModal className='p-4' show={showModal} size={'sm'} onHide={() => {}}>
        <div className='w-full flex flex-col justify-center'>
          <div className='flex flex-row justify-center w-full'>
            <img
              src={imageUtils.CircleSuccess}
              alt='forgot-password-success'
              className='w-14 h-14'
            />
          </div>
          <span className='text-xl font-semibold text-center mt-5'>
            {wordingUtils.SuccessMessage.pengajuanBerhasil}
          </span>
          {/* <span className='text-center font-semibold mt-2'>{`NIP: ${formik.values.nip}`}</span> */}
          <p className='text-center text-neutral-80'>
            {wordingUtils.ForgotPassword.okSubmitMsg}
          </p>
          <GButton
            size='large'
            variant='FILLED'
            className='flex-1 mt-8'
            loading={submitFn.isLoading}
            disabled={submitFn.isLoading || formik.values.nip?.length < 8}
            onClick={onModalClose}
          >
            {wordingUtils.Button.close}
          </GButton>
        </div>
      </GModal>
      <>
        <div className='mb-12 text-center'>
          <div className='mb-4 font-semibold text-fs-5 text-left w-full'>
            {wordingUtils.ForgotPassword.title}
          </div>
          {/* <div className='text-neutral-80 text-left'>{wordingUtils.ForgotPassword.subTitle}</div> */}
        </div>

        {formik.status && <GAlert className='mb-8'>{formik.status}</GAlert>}

        <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
          <div className='mb-8'>
            <FormLabel required className='mb-2'>
              {wordingUtils.General.NIP}
            </FormLabel>
            <FormText
              {...formik.getFieldProps('nip')}
              name='nip'
              type='text'
              placeholder={wordingUtils.Placeholder.inputNip}
              maxLength={50}
              error={formik.errors.nip}
              touched={formik.touched.nip}
              onChange={(e) => {
                formik.handleChange(e)
                formik.setErrors({})
              }}
            />
          </div>

          <div className='flex items-center justify-between gap-4'>
            <GButton
              type='submit'
              size='large'
              variant='FILLED'
              className='flex-1'
              loading={submitFn.isLoading}
              disabled={submitFn.isLoading || formik.values.nip?.length < 8}
            >
              {wordingUtils.Button.resetPassword}
            </GButton>
          </div>
        </form>
      </>
    </div>
  )
}

export default ForgotPassword

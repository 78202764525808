import {Mutex} from 'async-mutex'
import axios, {AxiosResponse, AxiosStatic} from 'axios'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import {BaseResponse, Response} from '../../app/models/api.types'
import {getExpiryUser} from 'src/app/utils/env-utils'

const mutex = new Mutex()
const plainAxios = axios.create()

export type RefreshTokenResponse = {
  access_token: string
  token_type: string
}

function AuditTrailFn(config: any, {cmsMenu, cmsUserAction}: any) {
  if (config.method !== 'get') {
    if (!config.headers['CMS-User-Action']) {
      config.headers['CMS-User-Action'] = cmsUserAction
    }
    if (!config.headers['CMS-Menu']) {
      config.headers['CMS-Menu'] = cmsMenu
    }
  }
}

export default function setupAxios(localAxios: AxiosStatic, store: any) {
  localAxios.defaults.baseURL = process.env.REACT_APP_API_URL
  plainAxios.defaults.baseURL = process.env.REACT_APP_API_URL
  const {dispatch} = store

  function ResetAuthCounterFN() {
    dispatch(AuthRedux.actions.setCounter(getExpiryUser()))
    dispatch(AuthRedux.actions.setDatetimeActive(new Date().getTime()))
  }

  localAxios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
        audit: {cmsMenu, cmsUserAction},
      } = store.getState()

      if (accessToken && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      // FOR AUDIT TRAIL
      AuditTrailFn(config, {cmsMenu, cmsUserAction})
      ResetAuthCounterFN()

      return config
    },
    (err: any) => {
      Promise.reject(err)
    }
  )
  localAxios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const {
        auth: {refreshToken},
      } = store.getState()
      const {dispatch} = store
      const {status} = error.response ?? {}
      if (status === 401) {
        return mutex.runExclusive(async () => {
          const refresh = await _refreshToken(refreshToken)

          if (refresh) {
            error.config.headers.Authorization = `Bearer ${store.getState().auth.accessToken}`
            try {
              return await plainAxios.request(error.config)
            } catch (err: any) {
              if (err.response?.status === 400) dispatch(AuthRedux.actions.logout())
              throw err
            }
          }
          dispatch(AuthRedux.actions.logout())
          throw error
        })
      }
      if (status === 410) {
        dispatch(AuthRedux.actions.logout())
        throw error
      }
      throw error
    }
  )
  const _refreshToken = async (refreshToken: string) => {
    const {dispatch} = store
    try {
      const result: AxiosResponse<BaseResponse<Response<RefreshTokenResponse>>> =
        await plainAxios.post(`/api/web/auth/refresh-token`, {
          refresh_token: refreshToken,
        })
      if (result.data)
        dispatch(
          AuthRedux.actions.fulfillToken({
            token: result.data.response_output?.detail?.access_token ?? '',
            refreshToken: refreshToken,
          })
        )
      return result.data
    } catch (error) {
      dispatch(AuthRedux.actions.logout())
      throw error
    }
  }
}

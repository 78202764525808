import {cva} from 'class-variance-authority'
export type IconButtonThemeEnum =
  | 'primary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'neutral'
  | null
  | undefined
export const chipTheme = cva(
  ['py-[2px]', 'px-[10px]', 'rounded-full', 'font-sanssemibold', 'text-sm'],
  {
    variants: {
      variant: {
        primary: ['bg-primary-light-2', 'text-primary'],
        success: ['bg-success-light-2', 'text-success'],
        danger: ['bg-danger-light-2', 'text-danger'],
        warning: ['bg-warning-light-2', 'text-warning'],
        info: ['bg-info-50', 'text-info-600'],
        neutral: ['bg-neutral-20', 'text-neutral-60'],
      },
    },
    compoundVariants: [
      {
        variant: 'success',
      },
    ],
    defaultVariants: {
      variant: 'success',
    },
  }
)

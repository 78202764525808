const SalesFrontingScreens = {
  SalesFronting: {
    PATH: '/sales-fronting',
    TITLE: 'Sales Fronting',
    TITLE_ALT: 'Approval Sales Fronting',
    TITLE_BREADCRUMB: 'Approval Sales Fronting',
    PERMISSION: 'SALES_FRONTING.read',
  },
  ViewSalesFronting: {
    PATH: '/sales-fronting/view/:id',
    TITLE: 'Sales Fronting',
    TITLE_ALT: 'Approval Prospecting',
    TITLE_BREADCRUMB: 'Approval Sales Fronting',
    PERMISSION: 'SALES_FRONTING.read',
  },
}

export default SalesFrontingScreens

import {FC} from 'react'
import {Link} from 'react-router-dom'
import {usePageData} from 'src/app/context/PageDataContext'
import {cn} from 'src/app/utils/cn-utils'
import {trimBreadcrumbs} from './Breadcrumbs.utils'
import {v4 as uuidv4} from 'uuid'


const Separator: FC = () => {
  return <span className='inline-block w-1 h-1 rounded-full shrink-0 bg-neutral-300'></span>
}

export const GBreadcrumbs: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()

  return (
    <div>
      {(pageDescription || (pageBreadcrumbs && pageBreadcrumbs.length > 0)) && (
        <ul className='flex flex-wrap items-center p-0 m-0 list-none text-fs-10'>
          {Array.from(pageBreadcrumbs ?? []).map((item) => (
            <li
              className={cn('flex items-center pr-2', {
                'text-neutral-80 dark:text-neutral-30': !item.isSeparator && item.isActive,
                'text-neutral-70 dark:text-neutral-20': !item.isSeparator && !item.isActive,
              })}
              key={`${item.path}-${uuidv4()}`}
            >
              {!item.isSeparator ? (
                <Link
                  className={cn('transition-all duration-200', {'text-neutral-800': item.isActive, 'text-neutral-500': !item.isActive})}
                  to={item.path}
                >
                  {trimBreadcrumbs(item.title)}
                </Link>
              ) : (
                <Separator />
              )}
            </li>
          ))}
          <li className='flex items-center text-neutral-800 dark:text-neutral-20'>
            {trimBreadcrumbs(pageDescription ?? pageTitle)}
          </li>
        </ul>
      )}
    </div>
  )
}

import {toAbsoluteUrl} from './asset-helpers-utils'

/* eslint-disable import/no-anonymous-default-export */
export default {
  CircleSuccess: toAbsoluteUrl('/media/logos/circle-success.svg'),
  CircleDanger: toAbsoluteUrl('/media/logos/circle-danger.svg'),
  ProfilePicSample: toAbsoluteUrl('/media/assets/profile-pic-sample.png'),
  BgHomeWave: toAbsoluteUrl('/media/assets/home-wave-background.svg'),
  BgHomeWave2: toAbsoluteUrl('/media/assets/home-wave-background-2.svg'),
  BgHomeWave3: toAbsoluteUrl('/media/assets/home-wave-background-3.svg'),
  LogoNavigatorBlue: toAbsoluteUrl('/media/logos/LogoNavigatorBlue.svg'),
  LogoNavigatorYellow: toAbsoluteUrl('/media/logos/LogoNavigatorYellow.svg'),
  OnlineDotGreen: toAbsoluteUrl('/media/assets/online-dot.svg'),
  DashLine: toAbsoluteUrl('/media/logos/DashedLine.svg'),
}

/* eslint-disable import/no-anonymous-default-export */
export default {
  Login: {
    Login: 'Login',
    usePrivateAccount: 'Mohon gunakan akun pribadi',
    Password: 'Password',
    verificationCaptcha: 'Verifikasi Captcha',
    inputCaptchaCode: 'Masukkan kode captcha',
    forgotPassword: 'Lupa Password?',
    contactAdmin: 'Mohon hubungi Administrator untuk info lebih lanjut',
  },
  ForgotPassword: {
    title: 'Lupa Password',
    subTitle: 'Pengajuan Reset Password mu akan diteruskan ke Admin yang bersangkutan.',
    okSubmitMsg: 'Pengajuan Reset Password berhasil dikirim dan akan diproses oleh Administrator.',
    okSubmitMsg2: 'Permohonan sedang diproses oleh Administrator.',
  },
  CreatePassword: {
    title: 'Ubah Password',
    subtitle: 'Silakan buat password baru untuk masuk ke akun',
    newPassword: 'Password Baru',
    confirmNewPassword: 'Konfirmasi Password',
    passRequire: 'Kombinasi password tidak sesuai. Password harus memiliki:',
    passRequire1: '• Minimal 8 karakter,',
    passRequire2: '• Gabungan huruf kapital dan huruf kecil',
    passRequire3: '• Terdapat angka (0-9)',
    passRequire4: '• Terdapat karakter khusus',
    confirmPassNotEqual: 'Konfirmasi Password tidak sesuai.',
    sameWithOldPass: 'Password baru tidak boleh sama dengan password sebelumnya.',
    blockedUser: 'User telah terblokir. Silahkan hubungi Administrator.',
  },
  General: {
    NIP: 'NIP',
    copyright: 'PT. Mandiri Taspen © 2024 ',
    reInputNewPassword: 'Konfirmasi password baru',
    action: 'Action',
    history: 'Riwayat',
    back: 'Kembali',
    abort: 'Batal',
    save: 'Simpan',
    loadingMessage: 'Data sedang dimuat...',
    checkDataWarning: 'Cek data terlebih dahulu sebelum melakukan proses persetujuan',
    requestType: 'Tipe Request',
    approvalStatus: 'Status Approval',
    dataStatus: 'Status Data',
    submissionDate: 'Tanggal Pengajuan',
    ktpNumber: 'No KTP',
    prospectinApproved: 'Pengajuan prospecting berhasil disetujui',
    prospectingRejected: 'Pengajuan prospecting ditolak',
  },
  Placeholder: {
    inputNip: 'Masukkan NIP',
    inputPassword: 'Masukkan password',
    inputNewPassword: 'Masukkan password baru',
    reInputNewPassword: 'Konfirmasi password baru',
    searchBankConfig: 'Cari produk nama bank',
    searchMenuConfig: 'Cari nama menu',
    productIdConfig: 'ID akan auto generate dari system',
    productCodeConfig: 'Masukan kode produk utama',
    productNameConfig: 'Masukan nama produk utama',
    productDescConfig: 'Masukan deskripsi',
    dataUploadType: 'Pilih Jenis Data Upload',
    productConfigCategoryType: 'Pilih Modal Kerja/Konsumtif',
    creditType: 'Pilih Jenis Kredit',
    insurance: 'Pilih Asuransi',
    sumCreditInsurance: 'Jumlah Kredit & Asuransi',
    inputRole: 'Masukkan Kode Role',
    inputRoleName: 'Masukkan Nama Role',
    loremIpsum:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute iruredd.',
    searchProductConfig: 'Cari product utama name',
  },
  Button: {
    close: 'Tutup',
    resetPassword: 'Reset Password',
    savePassword: 'Simpan Password',
    saveAndDownload: 'Simpan & Download',
    addData: 'Tambah Data',
    filter: 'Filter',
    back: 'Kembali',
    submissionType: 'Tipe Pengajuan',
    productCategory: 'Kategori Produk',
    cancel: 'Batal',
    save: 'Simpan',
    abort: 'Batal',
    sendRequest: 'Kirim Request',
    reject: 'Tolak',
    revise: 'Revisi',
    approve: 'Setuju',
    release: 'Release',
    releaseData: 'Release Data',
    next: 'Selanjutnya',
    downloadData: 'Download Data',
    download: 'Download',
    kirimRevisi: 'Kirim Revisi',
    submit: 'Submit',
    continue: 'Lanjut',
    approveBroadcast: 'Setujui Broadcast Pesan',
    verifikasiNIP: 'Verifikasi NIP',
    selectImage: 'Select Image',
    selectPhoto: 'Select Photo',
    changePhoto: 'Change Photo',
    removePhoto: 'Remove Photo',
    pilihFile: 'Pilih File',
    agreeContinue: 'Setuju & Lanjut',
  },
  ErrorMessage: {
    passNotMatch: 'Password baru harus sama dengan konfirmasi password baru.',
    invalidCaptcha: 'Captcha tidak sesuai.',
    invalidUserPassword: 'Kombinasi NIP & password tidak sesuai.',
    inputErrorLimit: 'User telah terblokir. Silahkan hubungi Administrator.',
    inputPasswordExpired:
      "Password telah melewati batas waktu penggunaan. Silahkan klik 'Lupa Password' untuk melakukan penggantian.",
    userOffline: 'Status Anda offline. Silahkan cek koneksi jaringan.',
    nipNotFound: 'NIP tidak ditemukan',
    userAlreadyLoggedIn: 'Gagal Login (User Anda Sedang Aktif)',
  },
  SuccessMessage: {
    pengajuanBerhasil: 'Pengajuan Berhasil',
    successSubmitKSm: 'Submit data simulasi KSM berhasil',
  },
  Config: {
    parameterMasterData: 'Parameter & Master Data',
    addProductConfigPageTitle: 'Tambah Data Product Configuration',
    editProductConfigPageTitle: 'Edit Data Product Configuration',
    detailProductConfigPageTitle: 'Detail Data Product Configuration',
    addBankConfigPageTitle: 'Tambah Data Bank Configuration',
    editBankConfigPageTitle: 'Edit Data Bank Configuration',
    detailBankConfigPageTitle: 'Detail Data Bank Configuration',
    addBranchConfigPageTitle: 'Tambah Data Branch Configuration',
    revBranchConfigPageTitle: '[Revisi] Tambah Data Branch Configuration',
    revProductConfigPageTitle: '[Revisi] Tambah Data Product Configuration',
    detailBranchConfigPageTitle: 'Detail Data Branch Configuration',
    editBranchConfigPageTitle: 'Edit Data Branch Configuration',
    editMenuConfigPageTitle: 'Edit Hak Akses',
    detailMenuConfigPageTitle: 'Detail Data Web Menu Configuration',
    detailMenuConfigPageTitleMob: 'Detail Data Mobile Menu Configuration',
    reviseMenuConfigPageTitle: '[Revisi] Edit Hak Akses',
  },
  ProductConfig: {
    mainProductID: 'ID Produk Utama',
    mainProductCode: 'Kode Produk Utama',
    mainProductName: 'Nama Produk Utama',
    description: 'Deskripsi',
  },
  ListUser: {
    headerTitle: 'Header Title',
    headerSubTitleUser: 'Header Sub Title User',
    title: 'Title',
    branchCode: 'Kode Cabang',
    branchName: 'Nama Cabang',
    name: 'Nama',
    role: 'Jabatan',
    distribution: 'Distribution',
    isActive: 'Status Aktif',
    onlineTooltip: 'Online Tooltip',
    list: 'Daftar Data',
  },
  AddUser: {
    title: 'Tambah Data User',
  },
  ListRole: {
    addRole: 'Tambah Role',
    deleteRole: 'Hapus Role',
    deleteRoleDesc: 'Perubahan yang dibuat perlu verifikasi dari Admin Checker terlebih dahulu',
    deleteRoleSuccess: 'Request hapus data berhasil dikirim',
    roleConfiguration: 'Role Configuration',
  },
  AddRole: {
    modalConfirmTitle: 'Tambah Role',
    modalConfirmDescription:
      'Data yang ditambah perlu verifikasi dari Admin Checker terlebih dahulu',
    modalSave: 'Kirim Request',
    saveSuccess: 'Request tambah data berhasil dikirim',
  },
  EditRole: {
    modalConfirmTitle: 'Edit Role',
    modalConfirmDescription:
      'Perubahan yang dibuat perlu verifikasi dari Admin Checker terlebih dahulu',
  },
  ReviseRole: {
    modalConfirmTitle: 'Data yang ditambah perlu verifikasi dari Admin Checker terlebih dahulu',
  },
  maxInput: {
    code: 50,
    general: 255,
    address: 300,
  },
  ViewUploadDataLeads: {
    rejectModalTitle: 'Apakah Yakin Akan Menolak Tambah Data?',
    rejectModalDesc:
      'Request tambah data akan tidak dilanjutkan apabila anda memilih Tolak, Klik tombol Tolak untuk menyetujui menolak tambah data atau klik tombol batal apabila ingin membatalkan penolakan.',
  },
  ApprovalIdebSlik: {
    custDetailSlikData: 'Detail Data SLIK Nasabah',
    successSendRequest: 'Request pengecekan SLIK berhasil dikirim',
    successApproveSlik: 'Hasil SLIK berhasil disetujui',
    rejectSendRequest: 'Request pengecekan SLIK ditolak',
    rejectSlik: 'Hasil SLIK ditolak',
    modalTitleReject: 'Apakah Yakin Akan Menolak Permohonan Pengecekan SLIK?',
    modalTitleRejectSlikResult: 'Apakah Yakin Akan Menolak Hasil SLIK?',
    modalTitleSendRequest: 'Apakah Yakin Ingin Memproses Permohonan Pengecekan SLIK?',
    modalDescReject:
      'Request permohonan pengecekan SLIK akan tidak dilanjutkan apabila anda memilih Tolak, Klik tombol Tolak apabila data/dokumen permohonan belum sesuai atau klik tombol batal untuk kembali',
    modalDescRejectSlikResult:
      'Klik tombol Tolak untuk menolak hasil SLIK atau klik tombol batal apabila ingin membatalkan penolakan.',
    modalDescSendRequest: 'Klik tombol Kirim Request untuk melakukan proses pengecekan SLIK',
    warningTextOnProgress: 'Data sedang dalam proses pengecekan SLIK oleh Mancis',
  },
  BlackBox: {
    daftarNasabah: 'Daftar Nasabah',
    detailDataNasabah: 'Detail Data Nasabah',
    msgSuccessReleaseData: 'Data nasabah berhasil direlease ke menu Follow Up',
    modalReleaseDataTitle: 'Apakah Yakin Ingin Me-Release Data Nasabah?',
    modalReleaseDataContent: 'Data akan masuk ke menu Follow Up',
    backToReFollowUp: 'Data akan masuk ke menu Re-Follow Up',
    backToClosing: 'Data akan masuk ke menu Closing',
    dataDiri: 'Data Diri',
    keteranganData: 'Keterangan Data',
    responseSuccessReleaseData: 'Data nasabah berhasil direlease ke menu Re-Follow Up',
    responseReleaseDataClosing: 'Data nasabah berhasil direlease ke menu Closing',
    cantReleaseWarning: 'Data nasabah tidak dapat direlease',
  },
  ApprovalProspecting: {
    approveModalTitle: 'Apakah Yakin Ingin Menyetujui Pengajuan Prospecting?',
    approveModalDesc: 'Data akan difollow up oleh ROP',
  },
  SalesFronting: {
    approvePromptTitle: 'Apakah Yakin Ingin Menyetujui Pengajuan Prospecting?',
    approvePromptDesc: 'Data akan di follow up oleh ROP',
    rejectPromptTitle: 'Apakah Yakin Akan Menolak Pengajuan Prospecting?',
    rejectPromptDesc:
      'Klik tombol Tolak untuk menolak pengajuan prospecting atau klik tombol batal apabila ingin membatalkan.',
  },
  Calculator: {
    dataNasabah: 'Data Nasabah',
    pilihNasabah: 'Pilih nasabah untuk menghitung simulasi kalkulator',
    submitEmanCalc:
      'Anda bisa menyimpan dan atau men-download hasil simulasi estimasi/manfaat dengan memilih salah satu tombol di bawah.',
  },
  Pinpen: {
    titleDetailStep3: 'Informasi Pengajuan Kredit Pensiun',
    pinpenSimplifiedTitle: 'Detail Data Kalkulator Si Pin-Pen Simplify',
    status_calon_data_debitur: 'Informasi Calon Data Debitur',
    status_informasi_keuangan: 'Informasi Keuangan Data Debitur',
    status_informasi_pengajuan_kredit: 'Informasi Pengajuan Kredit Pensiun',
    status_produk_yang_dipilih: 'Produk Yang Dipilih',
  },
}

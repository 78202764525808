const ReportScreens = {
  REPORT: {
    PATH: '/report',
    TITLE: 'Report',
    TITLE_ALT: 'Report',
    PERMISSION: '',
  },
  AKTIFITAS_PENGGUNA_LMS: {
    PATH: '/report/aktifitas-penggunaan-lms/:pos',
    TITLE: 'Aktifitas Penggunaan LMS',
    TITLE_ALT: 'Aktifitas Penggunaan LMS',
    PERMISSION: 'REPORT_AKTIVITAS_PENGGUNAAN_LMS.read'
  },
  SALES_FEEDBACK: {
    PATH: '/report/sales-feedback/:pos',
    TITLE: 'Sales Feedback',
    TITLE_ALT: 'Sales Feedback',
    PERMISSION: 'DASHBOARD_SALES_FEEDBACK.read'
  },
  DATA_BOOKING: {
    PATH: '/report/data-booking/:pos',
    TITLE: 'Report',
    TITLE_ALT: 'Data Booking',
    PERMISSION: 'REPORT_DATA_BOOKING.read'
  },
}

export default ReportScreens
